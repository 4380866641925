import React, { useState, useEffect } from "react";
import { Button, Grid, Tooltip } from "@material-ui/core";
import Controls from "../../UI/controls/Controls";
import { useForm, Form } from "../../UI/useForm";
// import {Paper, makeStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import Header from "../../UI/Header";
import { getDefaultImage } from "../images/DefaultImages";
import ImageConstants from "../images/Constants";
import Constants from "../../Services/Constants";
import AddImage from "../images/AddImage";
import Popup from "../../UI/popup";
import * as memberService from "../../Services/memberService";
import { getFilePath } from "../../Services/DigitalMedia";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";

import AddressAutoComplete from "../../UI/controls/AddressAutoComplete";

const MemberForm = (props) => {
  console.log("Props = ", props);
  let navigate = useNavigate();

  const useStyles = makeStyles((theme) => ({
    pageContent: {
      margin: theme.spacing(5),
      padding: theme.spacing(0),
      width: "100%",
    },
    searchInput: {
      width: "75%",
    },
    newButton: {
      position: "absolute",
      right: "10px",
    },
  }));
  /* 
  const memberQueryUrl = Constants.APIUrlString.Members + "/member?memberID=" + props.member.memberID;
  const { data: currentMember, error, isLoading, isEmpty } = useFetch( memberQueryUrl );
 */
  const currentMember = props.member;
  console.log("currentMember = ", currentMember);

  const classes = useStyles();
  const unavailableImagePath = getDefaultImage(
    ImageConstants.imageType.unavailableImage
  );

  //const { addOrEdit, recordForEdit } = props;
  function addOrEdit(record, resetFunction) {
    record.memberPictureUrl = memberImageUrl;
    record.updateDateTime = Date.now();
    delete record.memberPictureFileName;
    //record.memberPictureFileName = memberImageFileName;
    //console.log("addOrEdit member record: ", record);
    memberService.updateMember(record);
    props.setMember(record);
  }

  const recordForEdit = currentMember;
  if (!recordForEdit.hasOwnProperty("maritalStatus")) {
    recordForEdit.maritalStatus = "";
  }
  if (!recordForEdit.hasOwnProperty("memberGender")) {
    recordForEdit.memberGender = "";
  }

  const maritalStatusItems = [
    { id: "Single", title: "Single" },
    { id: "Married", title: "Married" },
    // { id: "Widowed", title: "Widowed" },
    { id: "Others", title: "Others" },
  ];

  const genderItems = [
    { id: "Male", title: "Male" },
    { id: "Female", title: "Female" },
    { id: "Others", title: "Others" },
  ];

  const pictureFileName =
    typeof currentMember.memberPictureFileName === "undefined" ||
    currentMember.memberPictureFileName === null
      ? unavailableImagePath
      : currentMember.memberPictureFileName;

  const pictureUrl = currentMember.memberPictureUrl;
  // (pictureFileName.substring(0, 4) === "http" ? pictureFileName : getFilePath(pictureFileName));

  const memberImagePath = "app/members/" + props.userID + "/images";

  /* 
  const initialFValues = currentMember;
  console.log("initialFValues = ", initialFValues);
   */
  const initialFValues = {
    memberID: currentMember.memberID,
    cognitoUserID: currentMember.cognitoUserID,
    createDateTime: currentMember.createDateTime,
    isActive: currentMember.isActive,
    memberEmail: currentMember.memberEmail,
    memberAltEmail: currentMember.memberAltEmail,
    memberName: currentMember.memberName,
    memberFirstName: currentMember.memberFirstName,
    memberLastName: currentMember.memberLastName,
    memberPhone: currentMember.memberPhone,
    memberAltPhone: currentMember.memberAltPhone,
    memberInitials: currentMember.memberInitials,
    memberPictureUrl: currentMember.memberPictureUrl,
    memberPictureFileName: pictureFileName,
    addressString1: currentMember.addressString1,
    addressString2: currentMember.addressString2,
    city: currentMember.city,
    postalCode: currentMember.postalCode,
    country: currentMember.country,
    countryShort: currentMember.countryShort,
    metroArea: currentMember.metroArea,
    coordinates: currentMember.coordinates,
    addressFullString: currentMember.addressFullString,
    memberDateOfBirth: currentMember.memberDateOfBirth,
    countryOfBirth: currentMember.countryOfBirth,
    cityOfBirth: currentMember.cityOfBirth,
    addressIsValidated: currentMember.addressIsValidated,
    maritalStatus: !currentMember.hasOwnProperty("maritalStatus")
      ? ""
      : currentMember.maritalStatus,
    memberGender: !currentMember.hasOwnProperty("memberGender")
      ? ""
      : currentMember.memberGender,
  };
  console.log("initialFValues = ", initialFValues);
  // Setup AddImage
  const [openAddImgPopup, setOpenAddImgPopup] = useState(false);
  const [memberImageUrl, setMemberImageUrl] = useState(pictureUrl);
  const [memberImageFileName, setMemberImageFileName] =
    useState(pictureFileName);
  const [fullString, setFullString] = useState(props.member.addressFullString);
  const [address, setAddress] = useState({
    addressString1: props.member.addressString1,
    city: props.member.city,
    postalCode: props.member.postalCode,
    metroArea: props.member.metroArea,
    province: props.member.province,
    country: props.member.country,
    countryShort: props.member.countryShort,
    coordinates: props.member.coordinates,
    fullString:
      props.member.addressString1 +
      "," +
      props.member.city +
      "," +
      props.member.postalCode +
      "," +
      props.member.country,
  });

  function isValidEmail(email) {
    //return /\S+@\S+\.\S+/.test(email);
    return true;
  }

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    console.log("Validate values = ", values);
    if ("memberFirstName" in fieldValues)
      temp.memberFirstName = fieldValues.memberFirstName
        ? ""
        : "First name is required.";
    if ("memberLastName" in fieldValues)
      temp.memberLastName = fieldValues.memberLastName
        ? ""
        : "Lastname is required.";
    if ("memberEmail" in fieldValues)
      // temp.memberEmail = fieldValues.memberEmail ? isValidEmail(fieldValues.memberEmail) : "e-mail address is required.";
      temp.memberEmail = fieldValues.memberEmail
        ? ""
        : "e-mail address is required.";
    if ("memberPhone" in fieldValues)
      temp.memberPhone = fieldValues.memberPhone
        ? ""
        : "Phone number is required .";
    if ("memberInitials" in fieldValues)
      temp.memberInitials = fieldValues.memberInitials
        ? ""
        : "Initials is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  // console.log("Values set = ", values);

  const handleSubmit = (e) => {
    // console.log("handleSubmit()");

    values.addressString1 = address.addressString1;
    values.city = address.city;
    values.postalCode = address.postalCode;
    values.province = address.province;
    values.metroArea = address.metroArea;
    values.coordinates = address.coordinates;
    values.country = address.country;
    values.countryShort = address.countryShort;
    values.addressFullString = address.fullString;
    values.preferredTimeZone = address.timeZone;

    // console.log("inside handleSubmit - values = ", values);
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
    navigate("/home");
  };

  const handleCancel = () => {
    navigate("/home");
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  return (
    <div>
      <Header
        userID={props.member.memberID}
        userName={currentMember.memberName}
        userEmail={currentMember.memberEmail}
        userPictureUrl={currentMember.memberPictureUrl}
        {...props}
      />
      <Paper className={classes.pageContent}>
        <Form onSubmit={handleSubmit} onCancel={handleCancel}>
          <Grid container>
            <Grid item xs={12}>
              <Tooltip title="Click to change the picture">
                <Button
                  onClick={() => {
                    setOpenAddImgPopup(true);
                  }}
                >
                  <img src={memberImageUrl} />
                </Button>
              </Tooltip>

              <Controls.Input
                name="memberFirstName"
                label="First Name"
                value={values.memberFirstName}
                onChange={handleInputChange}
                error={errors.memberFirstName}
              />
              <Controls.Input
                name="memberLastName"
                label="Last Name"
                value={values.memberLastName}
                onChange={handleInputChange}
                error={errors.memberLastName}
              />
              <Controls.Input
                disabled={true}
                label="e-mail Address"
                name="memberEmail"
                value={values.memberEmail}
                onChange={handleInputChange}
                error={errors.memberEmail}
              />
              <Controls.Input
                label="Phone Number"
                name="memberPhone"
                value={values.memberPhone}
                onChange={handleInputChange}
                error={errors.memberPhone}
              />

              <Controls.Input
                label="Intials"
                name="memberInitials"
                value={values.memberInitials}
                onChange={handleInputChange}
                error={errors.memberInitials}
              />

              <Accordion className="accordion" defaultExpanded={false}>
                <AccordionSummary
                  expandIcon={<ExpandCircleDownOutlinedIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography sx={{ fontStyle: "italic", fontWeight: "bold" }}>
                    Additional Info
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Controls.Select
                    name="memberGender"
                    label="Gender"
                    value={values.memberGender}
                    onChange={handleInputChange}
                    error={errors.memberGender}
                    options={genderItems}
                  />

                  <Controls.Select
                    name="maritalStatus"
                    label="Marital Status"
                    value={values.maritalStatus}
                    onChange={handleInputChange}
                    error={errors.maritalStatus}
                    options={maritalStatusItems}
                  />

                  <Controls.Input
                    label="Alternate Phone Number"
                    name="memberAltPhone"
                    value={values.memberAltPhone}
                    onChange={handleInputChange}
                    error={errors.memberAltPhone}
                  />

                  <Controls.Input
                    label="Alternate e-mail Address"
                    name="memberAltEmail"
                    value={values.memberAltEmail}
                    onChange={handleInputChange}
                    error={errors.memberAltEmail}
                  />
                </AccordionDetails>
              </Accordion>

              <Accordion className="accordion" defaultExpanded={false}>
                <AccordionSummary
                  expandIcon={<ExpandCircleDownOutlinedIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography sx={{ fontStyle: "italic", fontWeight: "bold" }}>
                    Address
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <AddressAutoComplete
                    address={fullString}
                    setAddress={setAddress}
                    setFullString={setFullString}
                  ></AddressAutoComplete>
                  {/* 
              <Controls.Input
                label="Number and street name"
                name="addressString1"
                value={values.addressString1}
                onChange={handleInputChange}
                error={errors.addressString1}
              />
 */}
                  <Controls.Input
                    label="Appt / Unit or Suite"
                    name="addressString2"
                    value={values.addressString2}
                    onChange={handleInputChange}
                    error={errors.addressString2}
                  />
                  <Controls.Input
                    disabled={true}
                    label="City"
                    name="city"
                    value={address.city}
                    onChange={handleInputChange}
                    error={errors.city}
                  />
                  <Controls.Input
                    disabled={true}
                    label="Postal Code"
                    name="postalCode"
                    value={address.postalCode}
                    onChange={handleInputChange}
                    error={errors.postalCode}
                  />
                  <Controls.Input
                    disabled={true}
                    label="Province/State/Region"
                    name="province"
                    value={address.province}
                    onChange={handleInputChange}
                    error={errors.province}
                  />
                  <Controls.Input
                    disabled={true}
                    label="Country"
                    name="country"
                    value={address.country}
                    onChange={handleInputChange}
                    error={errors.country}
                  />
                </AccordionDetails>
              </Accordion>

              <Popup
                title="Upload Image"
                openPopup={openAddImgPopup}
                setOpenPopup={setOpenAddImgPopup}
              >
                <AddImage
                  eventID={props.eventID}
                  eventName={props.eventName}
                  eventOwner={props.eventOwner}
                  userID={props.userID}
                  filePath={memberImagePath}
                  setImageUrl={setMemberImageUrl}
                  setImageFileName={setMemberImageFileName}
                  setOpenPopup={setOpenAddImgPopup}
                />
              </Popup>

              <div>
                {/* 
              <Controls.Button text="Reset" color="default" onClick={resetForm} />
 */}
                <Controls.Button
                  type="cancel"
                  text="Cancel"
                  onClick={handleCancel}
                />
                <Controls.Button type="submit" text="Submit" />
              </div>
            </Grid>
          </Grid>
        </Form>
      </Paper>
    </div>
  );
};

export default MemberForm;

import React, { useState, useEffect } from "react";
import { Button, Grid, Tooltip } from "@material-ui/core";
import Controls from "../../UI/controls/Controls";
import { useForm, Form } from "../../UI/useForm";

import AddressAutoComplete from "../../UI/controls/AddressAutoComplete";
import { updateEventAddress } from "../../Services/eventService";
import { updateMemberAddress } from "../../Services/memberService";

const AddressForm = (props) => {
  console.log("Props = ", props);

  const [fullString, setFullString] = useState(props.address.fullString);
  const [formAddress, setFormAddress] = useState({
    addressString1: props.address.addressString1,
    addressString2: props.address.addressString2,
    city: props.address.city,
    postalCode: props.address.postalCode,
    metroArea: props.address.metroArea,
    province: props.address.province,
    country: props.address.country,
    countryShort: props.address.countryShort,
    coordinates: props.address.coordinates,
    fullString: props.address.fullString,
    timeZone: props.address.timeZone,
  });

  const initialFValues = {
    addressString1: props.address.addressString1,
    addressString2: props.address.addressString2,
    city: props.address.city,
    postalCode: props.address.postalCode,
    metroArea: props.address.metroArea,
    province: props.address.province,
    country: props.address.country,
    countryShort: props.address.countryShort,
    coordinates: props.address.coordinates,
    fullString: props.address.fullString,
    timeZone: props.address.timeZone,
  };
  const recordForEdit = initialFValues;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);
  // console.log("Values set = ", values);

  function addOrEdit(record, resetFunction) {
    const latLng = { lat: record.coordinates.lat, lng: record.coordinates.lng };
    const addr = {
      addressString1: record.addressString1,
      addressString2: record.addressString2,
      city: record.city,
      country: record.country,
      countryShort: record.countryShort,
      formatted_address: record.formatted_address,
      fullString: record.fullString,
      metroArea: record.metroArea,
      postalCode: record.postalCode,
      province: record.province,
      coordinates: latLng,
      timeZone: record.timeZone,
    };
    console.log(" -- returning this address to the caller: ", addr);
    props.setAddress(addr);
  }

  const handleSubmit = (e) => {
    console.log("handleSubmit() - e = ", e);

    values.addressString1 = formAddress.addressString1;
    // values.addressString2 = formAddress.addressString2;
    values.city = formAddress.city;
    values.postalCode = formAddress.postalCode;
    values.province = formAddress.province;
    values.metroArea = formAddress.metroArea;
    values.coordinates = formAddress.coordinates;
    values.country = formAddress.country;
    values.countryShort = formAddress.countryShort;
    values.fullString = formAddress.fullString;
    values.formatted_address = formAddress.fullString;
    values.timeZone = formAddress.timeZone;

    // console.log("inside handleSubmit - values = ", values);
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }

    const entityName = props.hasOwnProperty("entityName")
      ? props.entityName
      : "undefined";
    const entityID = props.hasOwnProperty("entityID")
      ? props.entityID
      : "undefined";
    // Update the entity address
    switch (props.entityName) {
      case "event":
        console.log("Savind event's address - eventID = ", entityID);
        console.log("Addres = ", values);
        // Update the event address
        updateEventAddress(entityID, values);
        // TODO - send a notification that this event's address has been changed
        break;
        
      case "member":
        console.log("Savind member's address - memberID = ", entityID);
        console.log("Addres = ", values);
        updateMemberAddress(entityID, values);
        break;

      default:
        console.log("Unknown entity name: ", entityName);
        break;
        
    }

    // Close the popup window
    props.setOpenPopup(false);
  };

  const handleCancel = (e) => {
    props.setOpenPopup(false);
  };

  useEffect(() => {
    /* 
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
 */
  }, [recordForEdit]);

  return (
    // <Paper className={classes.pageContent}>
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <AddressAutoComplete
            address={fullString}
            setAddress={setFormAddress}
            setFullString={setFullString}
          ></AddressAutoComplete>

          <Controls.Input
            // disabled={true}
            label="Number and street name"
            name="addressString1"
            value={formAddress.addressString1}
            onChange={handleInputChange}
            error={errors.addressString1}
          />

          <Controls.Input
            label="Appt / Unit or Suite"
            name="addressString2"
            value={values.addressString2}
            onChange={handleInputChange}
            error={errors.addressString2}
          />
          <Controls.Input
            // disabled={true}
            label="City"
            name="city"
            value={formAddress.city}
            onChange={handleInputChange}
            error={errors.city}
          />
          <Controls.Input
            // disabled={true}
            label="Postal Code"
            name="postalCode"
            value={formAddress.postalCode}
            onChange={handleInputChange}
            error={errors.postalCode}
          />
          <Controls.Input
            // disabled={true}
            label="Province/State/Region"
            name="province"
            value={formAddress.province}
            onChange={handleInputChange}
            error={errors.province}
          />
          <Controls.Input
            // disabled={true}
            label="Country"
            name="country"
            value={formAddress.country}
            onChange={handleInputChange}
            error={errors.country}
          />
          <Controls.Input
            disabled={true}
            label="Venue Time Zone"
            name="timeZone"
            value={formAddress.timeZone}
            onChange={handleInputChange}
            error={errors.timeZone}
          />

          <div>
            <Controls.Button
              type="cancel"
              text="Cancel"
              onClick={handleCancel}
            />
            <Controls.Button type="submit" text="Submit" />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default AddressForm;

import * as React from "react";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers";
import { MuiThemeProvider } from "@material-ui/core";

const AnotherTimePicker = (props) => {
  //const [val, setVal] = React.useState(null);
  const { name, label, value, onChange } = props;
  const convertToDefEventPara = (name, value) => {
    console.log("name = ", name, "; value = ", value);
    return {
      target: {
        name,
        value,
      },
    };
  }
/* 
  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });
 */
  return (
    //    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <MuiThemeProvider>
      <TimePicker
        label={label}
        variant="inline"
        name={name}
        value={value}
        format="ampm"
        onChange={(time) => onChange(convertToDefEventPara(name,time))}
        renderInput={(params) => <TextField {...params} />}
      />
    </MuiThemeProvider>
    //    </LocalizationProvider>
  );
};

export default AnotherTimePicker;

import * as React from "react";
// Remove the unused import statement for useParams
// import { useParams } from "react-router";
// Remove the unused import statement for useState

// Remove the unused import statement for Header
import PrivateEventsList from "./Components/events/list/PrivateEventsList";
// Remove the unused import statement for Grid
// Remove the unused import statement for getDefaultMemberPicture
// Remove the unused import statement for getAllMembers

const HomePage = (props) => {
  const member = props.member;
  console.log("Props = ", props);

return (
    <span>
{/* 
      <Header userID={member.memberID} userName={member.memberName} userEmail={member.memberEmail} userPictureUrl={member.memberPictureUrl} {...props}/>
      <Box><Button></Button></Box>
 */}
      <PrivateEventsList userID={member.memberID} userName={member.memberName} userEmail={member.memberEmail} userPictureUrl={member.memberPictureUrl} {...props} />  
   </span>
  );
};

export default HomePage;

import React, { useState, useEffect } from "react";
import { Button, Grid, Tooltip } from "@material-ui/core";
import Controls from "../../../UI/controls/Controls";
import { useForm, Form } from "../../../UI/useForm";
import { Paper, makeStyles } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import ImageConstants from "../../images/Constants";
import Constants from "../../../Services/Constants";
import AddImage from "../../images/AddImage";
import Popup from "../../../UI/popup";
import IconButton from "@mui/material/IconButton";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import AddressAutoComplete from "../../../UI/controls/AddressAutoComplete";

const { DateTime } = require("luxon");

const EventAddForm = (props) => {
  const useStyles = makeStyles((theme) => ({
    pageContent: {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
      width: "100%",
    },
    searchInput: {
      width: "95%",
    },
    newButton: {
      position: "absolute",
      right: "10px",
    },
  }));

  const classes = useStyles();

  const currentTimeZone = props.currentTimeZone;
  const currentLocale = props.currentLocale !== undefined ? props.currentLocale : new Intl.DateTimeFormat().resolvedOptions().locale;

  const { addOrEdit, recordForEdit } = props;
  const [openAddImgPopup, setOpenAddImgPopup] = useState(false);
  const [venueImageUrl, setVenueImageUrl] = useState(
    ImageConstants.bucketURL + "/" + ImageConstants.imageFilePath.defaultImage
  );
  const [venueImageFileName, setVenueImageFileName] = useState(
    ImageConstants.imageFilePath.defaultImage
  );
  const [fullAddressString, setFullAddressString] = useState("");
  const [venueAddress, setVenueAddress] = useState({
    timeZone: currentTimeZone,
  });
  const eventImagePath = "app/events/" + props.newEventID + "/images";
  console.log("Event images path = ", eventImagePath);

  const eventTypeItems = [
    {
      id: "Family Party",
      title: "Family Party",
      image: ImageConstants.imageFilePath.defaultImage,
    },
    {
      id: "Birthday Party",
      title: "Birthday Party",
      image: ImageConstants.imageFilePath.HappyBirthImage,
    },
    {
      id: "Wedding",
      title: "Wedding Party",
      image: ImageConstants.imageFilePath.defaultImage,
    },
    {
      id: "Wedding Anniversary",
      title: "Wedding Anniversary",
      image: ImageConstants.imageFilePath.defaultImage,
    },
    {
      id: "Engagement",
      title: "Engagement Party",
      image: ImageConstants.imageFilePath.defaultImage,
    },
    {
      id: "Baby Shower",
      title: "Baby Shower",
      image: ImageConstants.imageFilePath.defaultImage,
    },
    {
      id: "Business Party",
      title: "Business Party",
      image: ImageConstants.imageFilePath.defaultImage,
    },
    // { id: "Cottage", title: "Cottage", image: ImageConstants.imageFilePath.defaultImage },
    {
      id: "Other",
      title: "Other",
      image: ImageConstants.imageFilePath.defaultImage,
    },
  ];

  // TimeZones
  const timeZonesArray = Intl.supportedValuesOf("timeZone");
  let timeZones = [{ id: "Same as venue", title: "Same as venue" }];

  for (let i = 0; i < timeZonesArray.length; i++) {
    timeZones[i + 1] = {
      id: timeZonesArray[i + 1],
      title: timeZonesArray[i + 1],
    };
  }

  const eventTZOffsetItems = [
    { id: "-8:00", title: "Vancouver" },
    { id: "-7:00", title: "Calgary/Denver" },
    { id: "-6:00", title: "Winnipeg/Dallas" },
    { id: "-5:00", title: "Toronto/New York" },
    { id: "-4:00", title: "Caracas" },
    { id: "-3:00", title: "Halifax/Brasillia" },
    { id: "0:00", title: "London/Dakar" },
    { id: "+1:00", title: "Douala/Lagos" },
    { id: "+2:00", title: "Paris/Berlin" },
    { id: "+3:00", title: "Nairobi/Moscow" },
    { id: "+4:00", title: "Abu Dabi/Dubai" },
    { id: "+5:30", title: "New Dehli/Mombai" },
    { id: "+6:00", title: "Dhaka" },
    { id: "+7:00", title: "Djakarta/Bangkok" },
    { id: "+8:00", title: "Singapore/Kuala Lumpur/Perth" },
    { id: "+8:30", title: "Adelaide" },
    { id: "+9:00", title: "Tokyo" },
    { id: "+10:00", title: "Sydney/Adelaide" },
    { id: "+12:00", title: "Auckland" },
  ];
  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "90%",
    maxHeight: "90%",
  });

  const initialFValues = {
    eventID: props.newEventID,
    eventName: "",
    eventDescription: "",
    eventType: "Birthday Party",
    eventDate: Date.now(),
    eventStartTime: "",
    eventEndTime: "",
    eventCreationDateTime: Date.now(),
    venueName: "",
    venuePhone: "",
    venueAddressString1: "",
    venueAddressString2: "",
    venueCity: "",
    venuePostalCode: "",
    venueCountry: "",
    venueCountryShort: "",
    venueMetroArea: "",
    venueAddress: "",
    venueCoordinates: {},
    venueImageUrl: venueImageUrl,
    ownerID: props.userID,
    eventOwner: props.userName,
    eventOwnerPictureUrl: props.userPictureUrl,
    eventOwnerEmail: props.userEmail,
    // invitedGuestsCount: 0,
    eventMaxPartySize: 0,
    venueMaxPartySize: 0,
    // declinedGuestsCount: 0,
    // acceptedGuestsCount: 0,
    eventSettings: {
      allowsGuestsToShare: true,
      maxInvitesPerGuest: 1,
      isGuestListVisible: true,
    },
    coHosts: [
      {
        memberID: props.userID,
        Name: props.userName,
        email: props.userEmail,
        pictureUrl: props.userPictureUrl,
      },
    ],
    eventStartDateTimeUTC: Date.now(),
    eventEndDateTimeUTC: Date.now(),
  };

  //console.log("Initial values = ", initialFValues);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("eventName" in fieldValues)
      temp.eventName = fieldValues.eventName ? "" : "Event name is required.";
    if ("eventDescription" in fieldValues)
      temp.eventDescription = fieldValues.eventDescription
        ? ""
        : "Event description is required.";
    if ("eventDate" in fieldValues)
      temp.eventDate = fieldValues.eventDate ? "" : "Event date is required.";

    if ("eventTZOffset" in fieldValues)
      temp.eventTZOffset = fieldValues.eventTZOffset
        ? ""
        : "Event Time Zone is required.";
    if ("eventType" in fieldValues)
      temp.eventType = fieldValues.eventType ? "" : "Event type is required.";
    if ("venueAddressString1" in fieldValues)
      temp.venueAddressString1 = fieldValues.venueAddressString1
        ? ""
        : "Address is required.";
    if ("venueCity" in fieldValues)
      temp.venueCity = fieldValues.venueCity
        ? ""
        : "Event venue city is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    values.venueAddressString1 = venueAddress.addressString1;
    values.venueCity = venueAddress.city;
    values.venuePostalCode = venueAddress.postalCode;
    values.venueProvince = venueAddress.province;
    values.venueMetroArea = venueAddress.metroArea;
    values.venueCoordinates = venueAddress.coordinates;
    values.venueCountry = venueAddress.country;
    values.venueCountryShort = venueAddress.countryShort;
    values.venueAddress = venueAddress.formatted_address;
    values.venueTimeZone = venueAddress.timeZone;

    const newEventDate = new Date(values.eventDate);
    const newStartTime = new Date(values.eventStartTime);
    const newEndTime = new Date(values.eventEndTime);

    values.eventStartDateTimeUTC = DateTime.fromObject(
      {
        year: newEventDate.getFullYear(),
        month: newEventDate.getMonth(),
        day: newEventDate.getDate(),
        hour: newStartTime.getHours(),
        minute: newStartTime.getMinutes(),
      },
      { zone: values.venueTimeZone }
    ).toUTC().ts;

    values.eventEndDateTimeUTC =
    values.eventEndTime === ""
      ? ""
      : DateTime.fromObject(
        {
          year: newEventDate.getFullYear(),
          month: newEventDate.getMonth(),
          day: newEventDate.getDate(),
          hour: newEndTime.getHours(),
          minute: newEndTime.getMinutes(),
        },
        { zone: values.venueTimeZone }
      ).toUTC().ts;

    console.log("values.eventStartDateTimeUTC = ", values.eventStartDateTimeUTC);
    console.log("values.eventEndDateTimeUTC = ", values.eventEndDateTimeUTC);

    e.preventDefault();
    if (validate()) {
      values.venueImageUrl = venueImageUrl;

      //console.log(" before submit event = ", values);

      addOrEdit(values, resetForm);
    }
  };

  const handleCancel = (e) => {
    //e.preventDefault();
    console.log(" Cancel event = ", e);
  };

  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
      });
    }
  }, [recordForEdit]);

  return (
    // <Paper className={classes.pageContent}>
    <Form onSubmit={handleSubmit} onCancel={handleCancel}>
      <Grid container>
        <Grid item xs={12}>
          <div>
            <Tooltip title="Click to replace image">
              <Button
                onClick={() => {
                  setOpenAddImgPopup(true);
                }}
              >
                <Img src={venueImageUrl} />
              </Button>
            </Tooltip>
          </div>

          <Controls.Select
            name="eventType"
            label="Event Type"
            value={values.eventType}
            onChange={handleInputChange}
            error={errors.eventType}
            options={eventTypeItems}
          />
          <Controls.Input
            name="eventName"
            label="Event Name"
            value={values.eventName}
            onChange={handleInputChange}
            error={errors.eventName}
          />
          <Controls.InputMulti
            name="eventDescription"
            value={values.eventDescription}
            label="Event Description"
            onChange={handleInputChange}
            error={errors.eventDescription}
            maxRows={7}
          />
          <Controls.Input
            label="Venue Name"
            name="venueName"
            value={values.venueName}
            onChange={handleInputChange}
            error={errors.venueName}
          />

          <AddressAutoComplete
            address={fullAddressString}
            setAddress={setVenueAddress}
            setFullString={setFullAddressString}
          ></AddressAutoComplete>

          <Controls.Input
            disabled={true}
            label="Number and street name"
            name="venueAddressString1"
            value={venueAddress.addressString1}
            onChange={handleInputChange}
            error={errors.venueAddressString1}
          />

          <Controls.Input
            label="Suite / Building / etc."
            name="venueAddressString2"
            value={values.venueAddressString2}
            onChange={handleInputChange}
            error={errors.venueAddressString2}
          />
          <Controls.Input
            disabled={true}
            label="City"
            name="venueCity"
            value={venueAddress.city}
            onChange={handleInputChange}
            error={errors.venueCity}
          />
          <Controls.Input
            disabled={true}
            label="Postal Code"
            name="venuePostalCode"
            value={venueAddress.postalCode}
            onChange={handleInputChange}
            error={errors.venuePostalCode}
          />
          <Controls.Input
            disabled={true}
            label="Country"
            name="venueCountry"
            value={venueAddress.country}
            onChange={handleInputChange}
            error={errors.venueCountry}
          />
          <Controls.Input
            label="Venue Phone Number"
            name="venuePhone"
            value={values.venuePhone}
            onChange={handleInputChange}
            error={errors.venuePhone}
          />

          <Controls.Input
            disabled={true}
            label="Venue Address"
            name="venueAddress"
            value={venueAddress.formatted_address}
            onChange={handleInputChange}
            error={errors.formatted_address}
          />
          <Controls.Input
            disabled={true}
            label="Venue Country Short"
            name="venueCountryShort"
            value={venueAddress.countryShort}
            onChange={handleInputChange}
            error={errors.countryShort}
          />
          <Controls.Input
            disabled={true}
            label="Venue time zone"
            name="venueTimeZone"
            value={venueAddress.timeZone}
            onChange={handleInputChange}
            error={errors.venueTimeZone}
          />

          <Controls.MyDatePicker
            name="eventDate"
            label="Event date"
            value={values.eventDate}
            onChange={handleInputChange}
            error={errors.eventDate}
            timeZone={currentTimeZone}
            locale={currentLocale}
              />
          <Controls.MyTimePicker
            name="eventStartTime"
            value={values.eventStartTime}
            label="Event Start Time"
            onChange={handleInputChange}
            error={errors.eventStartTime}
            timeZone={currentTimeZone}
            locale={currentLocale}
          />
          <Controls.MyTimePicker
            name="eventEndTime"
            value={values.eventEndTime}
            label="Event End Time"
            onChange={handleInputChange}
            error={errors.eventEndTime}
            timeZone={currentTimeZone}
            locale={currentLocale}
          />

          <div>
            {/* <Controls.Button text="Reset" color="default" onClick={resetForm} /> */}
            {/* <Controls.Button type="cancel" text="Cancel" /> */}
            <Controls.Button type="submit" text="Submit" />
          </div>

          <Popup
            title="Upload Image"
            openPopup={openAddImgPopup}
            setOpenPopup={setOpenAddImgPopup}
          >
            <AddImage
              eventID={props.newEventID}
              eventName={props.eventName}
              eventOwner={props.eventOwner}
              userID={props.userID}
              filePath={eventImagePath}
              setImageUrl={setVenueImageUrl}
              setImageFileName={setVenueImageFileName}
              setOpenPopup={setOpenAddImgPopup}
            />
          </Popup>
        </Grid>
      </Grid>
    </Form>
    // </Paper>
  );
};

export default EventAddForm;

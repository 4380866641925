import { useState, useEffect } from "react";

const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    console.log("useFetch() -- Fetching URL: ", url);

    const abortCont = new AbortController();
    const emptyResult = [];

    setTimeout(() => {
      console.log("Fetching URL: ", url);
      fetch(url, { signal: abortCont.signal })
        .then((res) => {
          if (!res.ok) {
            // error coming back from server
            throw Error("could not fetch the data for that resource");
          }
          return res.json();
        })
        .then((data) => {
          setIsLoading(false);
          setData(data);
          setError(null);
          setIsEmpty(!isLoading && (data === emptyResult || data.length === 0 || data === null ));
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            console.log("fetch aborted");
          } else {
            // auto catches network / connection error
            setIsLoading(false);
            setError(err.message);
            setIsEmpty(true);
          }
        });
    }, 1000);

    // abort the fetch
    return () => abortCont.abort();
  }, [url]);

  return { data, isLoading, error, isEmpty };
};

export default useFetch;

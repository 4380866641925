/* 
const APIHostName = {
  Members: process.env.MEMBER_HTTPAPI_ENDPOINT,
  Guests: process.env.GUEST_HTTPAPI_ENDPOINT,
  PrivEvents: process.env.EVENT_HTTPAPI_ENDPOINT,
  PubEvents: process.env.EVENT_HTTPAPI_ENDPOINT,
  Counters: process.env.COUNTER_HTTPAPI_ENDPOINT,
  Favorites: process.env.FAVORITE_HTTPAPI_ENDPOINT,
};
 */

const APIHostName = {
  Members: "iefh42es31.execute-api.us-west-2.amazonaws.com",
  Guests: "dnmm09r5o0.execute-api.us-west-2.amazonaws.com",
  PrivEvents: "j5f6ocpa5b.execute-api.us-west-2.amazonaws.com",
  PubEvents: "j5f6ocpa5b.execute-api.us-west-2.amazonaws.com",
  Counters: "y91pf741ob.execute-api.us-west-2.amazonaws.com",
  Favorites: "oquf5lqe22.execute-api.us-west-2.amazonaws.com",
}

const APIUrlString = {
  Members: "https://" + APIHostName.Members,
  Guests: "https://" + APIHostName.Guests,
  PrivEvents: "https://" + APIHostName.PrivEvents + "/priv",
  PubEvents: "https://" + APIHostName.PubEvents + "/pub",
  Counters: "https://" + APIHostName.Counters,
  Favorites: "https://" + APIHostName.Favorites,
}

const Separator = "~";

const Constants = {
  APIHostName,
  APIUrlString,
  Separator
}

export default Constants;
import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import useFetch from "../../hooks/useFetch";
import Constants from "../../Services/Constants";

const filter = createFilterOptions();

export default function MemberPickerAutoComplete(props) {
  const [selectedMember, setSelectedMember] = React.useState(null);
  const [openMemberDialog, toggleOpenMemberDialog] = React.useState(false);

  const url = "https://" + Constants.APIHostName.Members + "/members/page?ProjectionExpression=" +
    "memberName,memberFirstName,memberLastName,memberID,memberEmail,memberPhone,memberPictureUrl"  

  const {data: members, error, isLoading, isEmpty} = useFetch(url);
  console.log("Fetched members: ", members);

  const handleClose = () => {
    setDialogValue({
      memberName: "",
      memberFirstName: "",
      memberLastName: "",
      memberEmail: "",
      memberPhone: "",
    });
    toggleOpenMemberDialog(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    memberName: "",
    memberFirstName: "",
    memberLastName: "",
    memberEmail: "",
    memberPhone: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setSelectedMember({
      memberName: dialogValue.memberName,
      memberID: dialogValue.memberID,
      memberPictureUrl: dialogValue.memberPictureUrl,
      memberEmail: dialogValue.memberEmail,
      memberPhone: dialogValue.memberPhone,
      memberFirstName: dialogValue.memberFirstName,
      memberLastName: dialogValue.memberLastName,
    });
    handleClose();

    console.log("Added member is: ", dialogValue);
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={selectedMember}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpenMemberDialog(false);
              const names = newValue.split(" ");
              props.setValues({
                memberName: newValue.inputValue,
                memberFirstName: names[0],
                memberLastName: names[1],
                memberPictureUrl: "",
                memberPhone: "",
                memberEmail: "",
                memberID: "",
            })
                setDialogValue({
                memberName: newValue,
                memberFirstName: names[0],
                memberLastName: names[1],
                memberEmail: "",
                memberPhone: "",
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpenMemberDialog(false);
            const names = newValue.inputValue.split(" ");
            props.setValues({
              memberName: newValue.inputValue,
              memberFirstName: names[0],
              memberLastName: names[1],
              memberPictureUrl: "",
              memberPhone: "",
              memberEmail: "",
              memberID: "",
          })

            setDialogValue({
              memberName: newValue.inputValue,
              memberFirstName: names[0],
              memberLastName: names[1],
              memberEmail: "",
              memberPhone: "",
            });
          } else {
            setSelectedMember(newValue);
            console.log("Selected Member is: ", newValue);
            if (newValue !== null && newValue !== undefined) {
              console.log("setValue")
              props.setValues({
                memberName: newValue.memberName,
                memberFirstName: newValue.hasOwnProperty("memberFirstName") ? newValue.memberFirstName : "",
                memberLastName: newValue.hasOwnProperty("memberLastName") ? newValue.memberLastName : "",
                memberPictureUrl: newValue.memberPictureUrl,
                memberPhone: newValue.memberPhone,
                memberEmail: newValue.memberEmail,
                memberID: newValue.memberID,
              });
            }
        }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              memberName: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={members}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
           return option.memberName;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => (
          <li {...props}>{option.memberName}</li>
        )}
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label="Type member first and last name" />
        )}
      />
      <Dialog open={openMemberDialog} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Person missing from the list?</DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.memberName}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  memberName: event.target.value,
                })
              }
              label="Full name"
              type="text"
              variant="standard"
            />{" "}
            <br />
            <TextField
              autoFocus
              margin="dense"
              id="firstName"
              value={dialogValue.memberFirstName}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  memberFirstName: event.target.value,
                })
              }
              label="First name"
              type="text"
              variant="standard"
            />{" "}
            <br />
            <TextField
              autoFocus
              margin="dense"
              id="lastName"
              value={dialogValue.memberLastName}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  memberLastName: event.target.value,
                })
              }
              label="Last name"
              type="text"
              variant="standard"
            />{" "}
            <br />
            <TextField
              margin="dense"
              id="email"
              value={dialogValue.memberEmail}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  memberEmail: event.target.value,
                })
              }
              label="Email"
              type="string"
              variant="standard"
            />
            <br />
            <TextField
              margin="dense"
              id="phone"
              value={dialogValue.memberPhone}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  memberPhone: event.target.value,
                })
              }
              label="Phone"
              type="string"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import ActionButton from "./ActionButton";
import InputMulti from "./InputMulti";
import MyTimePicker from "./MyTimePicker";
import MyDatePicker from "./MyDatePicker";
import AnotherTimePicker from "./AnotherTimePicker";
import AnotherDatePicker from "./AnotherDatePicker";

const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    DatePicker,
    Button,
    ActionButton,
    InputMulti,
    MyTimePicker,
    MyDatePicker,
    AnotherTimePicker,
    AnotherDatePicker
}

export default Controls;
import React, { useState, useEffect } from "react";
import { Grid, styled, Tooltip } from "@material-ui/core";
import Controls from "../../../UI/controls/Controls";
import { useForm, Form } from "../../../UI/useForm";
// import {Paper, makeStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import Popup from "../../../UI/popup";
import AddImage from "../../images/AddImage";

const EventWhatForm = (props) => {
  const useStyles = makeStyles((theme) => ({
    pageContent: {
      margin: theme.spacing(5),
      padding: theme.spacing(0),
      width: "100%",
    },
    searchInput: {
      width: "75%",
    },
    newButton: {
      position: "absolute",
      right: "10px",
    },
  }));

  const classes = useStyles();

  const { addOrEdit, recordForEdit } = props;
  const [openAddImgPopup, setOpenAddImgPopup] = useState(false);
  const [venueImageUrl, setVenueImageUrl] = useState(props.venueImageUrl);
  const [venueImageFileName, setVenueImageFileName] = useState();

  const eventTypeItems = [
    { id: "Baby Shower", title: "Baby Shower" },
    { id: "Birthday Party", title: "Birthday Party" },
    { id: "Wedding", title: "Wedding Party" },
    { id: "Wedding Anniversary", title: "Wedding Anniversary" },
    { id: "Engagement", title: "Engagement Party" },
    { id: "Business Party", title: "Business Party" },
    { id: "Cottage", title: "Cottage" },
    { id: "Others", title: "Others" },
  ];

  const eventMaxPartySizeItems = [
    { id: 10, title: "max 10"},
    { id: 20, title: "max 20"},
    { id: 30, title: "max 30"},
    { id: 40, title: "max 40"},
    { id: 50, title: "max 50"},
    { id: 100, title: "max 100"},
    { id: 150, title: "max 150"},
    { id: 999, title: "Unlimited"},
  ];
  
  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "90%",
    maxHeight: "90%",
  });

  const initialFValues = {
    eventID: props.eventID,
    eventName: props.eventName,
    eventDescription: props.eventDescription,
    eventType: props.eventType,
    eventMaxPartySize: props.eventMaxPartySize,
    venueImageUrl: props.venueImageUrl,
  };
  const eventImagePath = "app/events/" + props.eventID + "/images";

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("eventName" in fieldValues)
      temp.eventName = fieldValues.eventName ? "" : "Event name is required.";
    if ("eventDescription" in fieldValues)
      temp.eventDescription = fieldValues.eventDescription
        ? ""
        : "Event description is required.";
    if ("venueName" in fieldValues)
      temp.venueName = fieldValues.venueName
        ? ""
        : "Event venue name is required.";
    if ("eventType" in fieldValues)
      temp.eventType = fieldValues.eventType
        ? ""
        : "Event type is required.";
      setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      values.venueImageUrl = venueImageUrl;
      addOrEdit(values, resetForm);
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  return (
    <Paper className={classes.pageContent}>
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>

          <Tooltip title="Click to replace image">
              <Button
                onClick={() => {
                  setOpenAddImgPopup(true);
                }}
              >
                <Img src={venueImageUrl} />
              </Button>
            </Tooltip>

          <Controls.Select
              name="eventType"
              label="Event Type"
              value={values.eventType}
              onChange={handleInputChange}
              error={errors.eventType}
              options={eventTypeItems}
            />
            <Controls.Input
              name="eventName"
              label="Event Name"
              value={values.eventName}
              onChange={handleInputChange}
              error={errors.eventName}
            />
             <Controls.InputMulti
              name="eventDescription"
              value={values.eventDescription}
              label="Event Description"
              onChange={handleInputChange}
              error={errors.eventDescription}
              maxRows={7}
            />

            <div>
              <Controls.Button
                text="Reset"
                color="default"
                onClick={resetForm}
              />
              <Controls.Button type="submit" text="Submit" />
            </div>


            <Popup
            title="Upload Image"
            openPopup={openAddImgPopup}
            setOpenPopup={setOpenAddImgPopup}
          >
            <AddImage
              eventID={props.eventID}
              eventName={props.eventName}
              eventOwner={props.eventOwner}
              userID={props.userID}
              filePath={eventImagePath}
              setImageUrl={setVenueImageUrl}
              setImageFileName={setVenueImageFileName}
              setOpenPopup={setOpenAddImgPopup}
            />
          </Popup>

          </Grid>
        </Grid>
      </Form>
    </Paper>
  );
};

export default EventWhatForm;

import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import zhCN from "date-fns/locale/zh-CN";
import fr from "date-fns/locale/fr";

const locales = { 'en-us': undefined, 'en-gb': enGB, 'zh-cn': zhCN, 'de-DE' : de, 'fr-FR' : fr, 'fr-CA' : fr };

const MyTimePicker = (props) => {
  const { name, label, value, onChange, timeZone, locale } = props;

  const convertToDefEventPara = (name, value) => ({
    target: {
      name, value,
    },
  });

  return (
    <LocalizationProvider 
      dateAdapter={AdapterDateFns}
      adapterLocale={locales[locale]} >
      <TimePicker
        size="small"
        label={label}
        name={name}
        value={value}
        format="HH:MMZ"
        onChange={(time) => onChange(convertToDefEventPara(name, time))}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

export default MyTimePicker;

import axios from "axios";
import Constants from "./Constants";

/*
 ** Function: buildResponse
 ** Purpose: format  error code/message
 */
 function buildResponse(statusCode, body) {
    return {
      statusCode: statusCode,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
  }
  
/*
** Function: sendNotification
*/
export async function sendNotification(notification) {
  const url = Constants.NotificationUrl;
  console.log("Notification URL: ", url);

  const notificationCreated = await axios.post(url, notification)
    .then((res) => {
      const result = res.data.Item;
      console.log("notificationService.sendNotification return: ", result);
      return buildResponse(200, result);
    })
    .catch((error) => {
      console.log(error);
      return buildResponse(500, { error: error } );
    });

  // Final return
  return notificationCreated;
}
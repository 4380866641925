import * as React from "react";
import { useState } from "react";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import { CardActionArea } from "@mui/material";
import Grid from "@mui/material/Grid";

import "./GuestCard.css";
import Button from "../../UI/controls/Button";
import Popup from "../../UI/popup";
import Controls from "../../UI/controls/Controls";
import { Padding } from "@mui/icons-material";
import * as guestService from "../../Services/guestService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function GuestCard(props) {
  var deadLinneDateTimeString;
  if (!Number.isNaN(props.guest.deadLineDateTime)) {
    deadLinneDateTimeString = new Date(parseInt(props.guest.deadLineDateTime));
  } else {
    /* console.log(
      "DeadLineDateTime is not a number: ",
      props.guest.deadLineDateTime
    ); */
    deadLinneDateTimeString = Date.now();
  }
  const [openPopup, setOpenPopup] = useState(false);
  const [newDeadLineDateTime, setNewDeadLineDateTime] = useState(
    deadLinneDateTimeString
  );
  const [popupAction, setPopupAction] = useState("Reminder");
  const [popupTitle, setPopupTitle] = useState("Send a reminder");

  // console.log("Dead Line UTC", props.guest.deadLineDateTime);
  // console.log("Dead Line Date: ", deadLinneDateTimeString);
  const currentMember = props.member;
  const guests = props.guests;
  const isOwner = props.eventOwnerID === props.member.memberID;

  const notify = (toastText, success) => {
    if (success) {
      toast.success(toastText, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.error(toastText, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const guest = props.guest;
  console.log("Props = ", props);

  const currentLocale =
    props.currentLocale !== ""
      ? props.currentLocale
      : Intl.NumberFormat().resolvedOptions().currentLocale;
  const venueTimeZone = props.venueTimeZone;
  const currentTimeZone =
    props.currentTimeZone !== "" &&
    props.currentTimeZone !== undefined &&
    props.currentTimeZone !== "undefined"
      ? props.currentTimeZone
      : Intl.DateTimeFormat().resolvedOptions().timeZone;
  const preferredTimeZone =
    currentMember.hasOwnProperty("preferredTimeZone") &&
    currentMember.preferredTimeZone !== undefined &&
    currentMember.preferredTimeZone !== "undefined"
      ? props.member.preferredTimeZone
      : currentTimeZone;

  var rsvpStatus = "invite sent on " + guest.invitationSentDateTime;

  const optionLocalTime = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: preferredTimeZone,
    timeZoneName: "shortGeneric",
  };
  const optionVenueTime = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: venueTimeZone,
    timeZoneName: "shortGeneric",
  };

  const optionLocalDate = {
    year: "numeric",
    month: "short",
    day: "numeric",
    // hour: "numeric",
    // minute: "numeric",
    timeZone: preferredTimeZone,
    // timeZoneName: "shortGeneric",
  };
  const optionVenueDate = {
    year: "numeric",
    month: "short",
    day: "numeric",
    // hour: "numeric",
    // minute: "numeric",
    timeZone: venueTimeZone,
    // timeZoneName: "shortGeneric",
  };

  const isValidResponse = (resp) => {
    if (resp === "accepted" || resp === "declined" || resp === "notsure")
      return true;
    return false;
  };

  const invitationSentDateTimeLocalDate =
    guest.invitationSentDateTime !== undefined
      ? new Date(Math.floor(guest.invitationSentDateTime)).toLocaleDateString(
          currentLocale,
          optionLocalDate
        )
      : "";

  const invitationSentDateTimeLocalTime =
    guest.invitationSentDateTime !== undefined
      ? new Date(Math.floor(guest.invitationSentDateTime)).toLocaleDateString(
          currentLocale,
          optionLocalTime
        )
      : "";

  const responseSentDateTimeLocalDate =
    guest.responseSentDateTime !== undefined &&
    guest.responseSentDateTime !== "" &&
    guest.responseSentDateTime !== null
      ? new Date(Math.floor(guest.responseSentDateTime)).toLocaleDateString(
          currentLocale,
          optionLocalDate
        )
      : "";

  const responseSentDateTimeLocalTime =
    guest.responseSentDateTime !== undefined &&
    guest.responseSentDateTime !== "" &&
    guest.responseSentDateTime !== null
      ? new Date(Math.floor(guest.responseSentDateTime)).toLocaleDateString(
          currentLocale,
          optionLocalTime
        )
      : "";

  const deadLineDateTimeLocalDate =
    guest.deadLineDateTime !== undefined &&
    guest.deadLineDateTime !== "" &&
    guest.deadLineDateTime !== null
      ? new Date(Math.floor(guest.deadLineDateTime)).toLocaleDateString(
          currentLocale,
          optionLocalDate
        )
      : "";

  const deadLineDateTimeLocalTime =
    guest.deadLineDateTime !== undefined &&
    guest.deadLineDateTime !== "" &&
    guest.deadLineDateTime !== null
      ? new Date(Math.floor(guest.deadLineDateTime)).toLocaleDateString(
          currentLocale,
          optionLocalTime
        )
      : "";

  const deadLine =
    deadLineDateTimeLocalDate !== "" && responseSentDateTimeLocalDate === ""
      ? " / RSVP by " + deadLineDateTimeLocalDate
      : "";

  const hasResponded = isValidResponse(guest.guestResponse);
  // console.log("Has responded:", hasResponded);
  const hasExpired = Date.now() > guest.deadLineDateTime;
  // console.log("Date now:", Date.now());
  // console.log("DeadLine:", guest.deadLineDateTime);
  // console.log("Has expired:", hasExpired);

  const respondedText =
    guest.hasOwnProperty("guestResponse") &&
    guest.guestResponse !== "undefined" &&
    guest.guestResponse !== ""
      ? guest.guestResponse.charAt(0).toUpperCase() +
        guest.guestResponse.slice(1)
      : "";

  rsvpStatus =
    responseSentDateTimeLocalDate !== ""
      ? respondedText + " on " + responseSentDateTimeLocalDate
      : hasExpired
      ? "RSVP deadline passed. It was " + deadLineDateTimeLocalDate
      : "Invite sent on " + invitationSentDateTimeLocalDate + deadLine;

  const handleCardClick = (e) => {
    setPopupAction("ShowDetails");
    setPopupTitle("Invitation details");
    setOpenPopup(true);
  };

  const handleReminderClick = (e) => {
    setPopupAction("Reminder");
    setPopupTitle("Send a reminder");
    setOpenPopup(true);
  };

  const handleUnInviteClick = (e) => {
    setPopupAction("UnInvite");
    setPopupTitle("Un-invite this guest");
    setOpenPopup(true);
  };

  const handleExtendTimeClick = (e) => {
    setPopupAction("Deadline");
    setPopupTitle("Extend the deadline");
    setOpenPopup(true);
  };

  const removeGuestFromArray = (guestsArray, guestID) => {
    const newGuestsArray = guestsArray.filter(function (obj) {
      return obj.guestID !== guestID;
    });
    return newGuestsArray;
  };
  const handleSubmit = () => {
    // console.log("Submit - ", popupAction);
    const myDate = new Date(newDeadLineDateTime);
    const newDeadLineDateTimeUTC = Date.UTC(
      myDate.getUTCFullYear(),
      myDate.getUTCMonth(),
      myDate.getUTCDate(),
      myDate.getUTCHours(),
      myDate.getUTCMinutes(),
      myDate.getUTCSeconds()
    );
    switch (popupAction) {
      case "Deadline":
        const newGuest = guest;
        newGuest.deadLineDateTime = newDeadLineDateTimeUTC;
        guestService.updateDeadLine(newGuest);
        setOpenPopup(false);
        guest.deadLineDateTime = newDeadLineDateTimeUTC;
        notify(
          "New deadline set successfully for <" + guest.memberName + ">",
          true
        );
        break;

      case "Reminder":
        guestService.sendReminder(guest);
        setOpenPopup(false);
        notify(
          "Reminder sent successfully to <" + guest.memberName + ">",
          true
        );
        break;

      case "UnInvite":
        try {
          guestService.deleteGuest(guest);
          notify(
            "Guest <" + guest.memberName + "> successfully un-invited",
            true
          );
          const newGuests = removeGuestFromArray(guests, guest.guestID);
          // console.log("New guest list: ", newGuests);
          props.setGuests(newGuests);
        } catch (error) {
          console.error(error);
          notify(
            "Guest <" + guest.memberName + "> successfully un-invited",
            false
          );
        }
        setOpenPopup(false);
        break;

      default:
        break;
    }
  };

  return (
    <span>
      <Card sx={{ width: "100%", borderRadius: "10pt" }} variant="outlined">
        {/* When the current user is the owner, he should be able to view the details of the guest */}
        {props.eventOwnerID === props.member.memberID && (
          <CardActionArea onClick={handleCardClick}>
            <CardHeader
              size="small"
              sx={{ paddingBottom: "0", paddingTop: "1pt" }}
              avatar={
                <Avatar
                  src={guest.memberPictureUrl}
                  alt={guest.memberName}
                  aria-label={guest.memberName}
                />
              }
              title={guest.memberName + " - " + guest.memberEmail}
              subheader={rsvpStatus}
            />
          </CardActionArea>
        )}
        {/* When the current user is NOT the owner, he should NOT be able to view the details of the guest */}
        {props.eventOwnerID !== props.member.memberID && (
          <CardActionArea>
            {" "}
            {/* No onClick event */}
            <CardHeader
              size="small"
              sx={{ paddingBottom: "0", paddingTop: "1pt" }}
              avatar={
                <Avatar
                  src={guest.memberPictureUrl}
                  alt={guest.memberName}
                  aria-label={guest.memberName}
                />
              }
              title={guest.memberName + " - " + guest.memberEmail}
              subheader={rsvpStatus}
            />
          </CardActionArea>
        )}

        {isOwner && (
          <CardActions sx={{ justifyContent: "center" }}>
            {!hasResponded && !hasExpired && (
              <Button
                disabled={hasResponded}
                text="Reminder"
                size="small"
                sx={{ borderRadius: "5pt" }}
                onClick={handleReminderClick}
              />
            )}
            {hasExpired && (
              <Button
                disabled={!hasExpired}
                text="Extend time"
                size="small"
                sx={{ borderRadius: "5pt" }}
                onClick={handleExtendTimeClick}
              />
            )}
            <Button
              text="Un-Invite"
              size="small"
              sx={{ borderRadius: "5pt" }}
              onClick={handleUnInviteClick}
            />
          </CardActions>
        )}
      </Card>

      <ToastContainer />

      <Popup
        title={popupTitle}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Controls.Input
            sx={{ paddingBottom: "10pt", width: "90%" }}
            disabled={true}
            name="memberName"
            label="Guest name"
            value={guest.memberName}
          />
          <Controls.Input
            sx={{ paddingBottom: "10pt", width: "90%" }}
            disabled={true}
            name="memberEmail"
            label="Guest email address"
            value={guest.memberEmail}
          />
          <Controls.Input
            sx={{ paddingBottom: "10pt", width: "90%" }}
            disabled={true}
            name="memberPhone"
            label="Guest phone number"
            value={guest.memberPhone}
          />
          <Controls.Input
            sx={{ paddingBottom: "10pt", width: "90%" }}
            disabled={true}
            name="invitationSent"
            label="Date & time the invitation was sent"
            value={invitationSentDateTimeLocalTime}
          />
          {hasResponded && (
            <span>
              <Controls.Input
                disabled={true}
                sx={{ paddingBottom: "10pt", width: "90%" }}
                name="guestResponse"
                label="Guest response"
                value={guest.guestResponse}
              />

              <Controls.Input
                disabled={true}
                sx={{ paddingBottom: "10pt", width: "90%" }}
                name="responseDateTime"
                label="Date & time the response was received"
                value={responseSentDateTimeLocalTime}
              />

              <Controls.Input
                disabled={true}
                sx={{ paddingBottom: "10pt", width: "90%" }}
                name="guestComments"
                label="Guest response comment(s)"
                value={guest.guestComments}
              />
            </span>
          )}
          {popupAction === "Deadline" && (
            <span>
              <Controls.Input
                disabled={true}
                sx={{ paddingBottom: "10pt", width: "90%" }}
                name="deadLineDateTimeLocalTime"
                label="Initial RSVP deadline"
                value={deadLineDateTimeLocalTime}
              />

              <Controls.MyDatePicker
                sx={{ paddingBottom: "10pt", width: "90%" }}
                name="newDeadLineDateTime"
                label="New RSVP deadline"
                value={newDeadLineDateTime}
                onChange={(e) => setNewDeadLineDateTime(e.target.value)}
                timeZone={venueTimeZone}
                locale={currentLocale}
              />
            </span>
          )}
        </Grid>
        <Grid container direction="row" justifyContent="flex-end">
          <Controls.Button type="submit" text="Submit" onClick={handleSubmit} />
        </Grid>
      </Popup>
    </span>
  );
}

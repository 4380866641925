import React, { useEffect } from "react";
import { useState } from "react";

import Box from "@mui/material/Box";
import PrivateEventCard from "./PrivateEventCard";
import NoDataFound from "../../../UI/NoDataFound";
import EventAddForm from "../details/EventAddForm";
import Constants from "../../../Services/Constants";
import useFetch from "../../../hooks/useFetch";
import LoadingPage from "../../../UI/LoadingPage";
import { Button, Grid } from "@material-ui/core";
import * as eventService from "../../../Services/eventService";
import "../Events.css";
import Popup from "../../../UI/popup";
import BottomBar from "../../../UI/BottomBar";
import Header from "../../../UI/Header";
import { useNavigate } from "react-router-dom";
import UnderConstruction from "../../Common/UnderConstruction";

const PrivateEventsList = (props) => {
  const ownerID = props.userID;
  let navigate = useNavigate();

  console.log("Props = ", props);
  console.log("Email = ", props.member.memberEmail);

  // Get all events the owner created or is invited at
  //console.log("Fetching all events owned or invited at for:", ownerID);
  const ownAPIUrl =
    Constants.APIUrlString.PrivEvents + "/myevents?ownerID=" + ownerID 
          + "&userEmail=" + props.member.memberEmail;
  //console.log("REST API Url: ", ownAPIUrl);

  // Set openPopup Guest state
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState(null);
  const [queryString, setQueryString] = useState("");
  const [newEventID, setNewEventID] = useState("");
  const currentTimeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [openUnderConstructionPopup, setOpenUnderConstructionPopup] = useState(false);
  const { data: events, error, isLoading, isEmpty } = useFetch(ownAPIUrl);
  if (error) {
    console.log("Error: ", error);
  }
  let filteredEvents = [];
  let sortedEvents = [];
  const currentLocale = props.currentLocale !== undefined ? props.currentLocale : new Intl.NumberFormat().resolvedOptions().locale;

  //filteredEvents = events.filter(event => eventIncludesQuery(event, queryString));
/* 
  useEffect(() => {
 */
    filteredEvents = addFieldsAnnFilter(events, queryString);
    console.log("Filtered list = ", filteredEvents);
    sortedEvents = filteredEvents.sort((a, b) => b.eventDateTimeUTC - a.eventDateTimeUTC);
    console.log("Sorted list = ", sortedEvents);
/* 
  }, [events, queryString]);
 */
  function addFieldsAnnFilter(oldList, query) {
    const newList = [];
    let isoDate = "";
    const today = new Date();
    const todayUTC = Date.UTC( today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0 );
    const passed90DaysUTC = todayUTC - (30 * 24 * 3600);
    for (const element of oldList) {
      const coHosts = !element.hasOwnProperty("coHosts") ? "" :JSON.stringify(element.coHosts);
      element.isOwner = (element.ownerID === props.member.memberID);
      element.isCoHost = coHosts.includes(props.member.memberEmail);
      element.isPassed90Days = (element.eventDateTimeUTC <= passed90DaysUTC); // true if event date is pased for more than 90 days
      element.isActive = (element.eventDateTimeUTC >= Date.UTC());
      if (eventIncludesQuery(element, query)) {
        newList.push(element);
      }
    }
    return newList;
  }

  function eventIncludesQuery(evt, query) {
    let text = "";
    for (const key of ["eventName", "eventDescription", "eventOwner", "eventType", "venueCity", "venueName", "venueCountry"]) {
      if (evt.hasOwnProperty(key)) {
          text = text + "~" + evt[key];
      }
    }

    if (text.length > 0) {
      return text.toLowerCase().includes(query.toLowerCase());
    }
    return true;
  }

  const UUIDGeneratorBrowser = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );


  // Add or edit a event
  const addOrEdit = (event, resetForm) => {
    let recs = events;
    
    if (event.eventID === "") {
      event.eventID = UUIDGeneratorBrowser();
    }
      console.log("Generated eventID: ", event.eventID);
      // Set the values of the guest record before inserting the record
      event.eventOwner = props.userName;
      event.eventOwnerEmail = props.userEmail;
      event.eventOwnerPictureUrl = props.userPictureUrl;
      event.ownerID = props.userID;

      event.eventDate = getStringDate(event.eventDate);
      event.eventStartTime = getStringTime(event.eventStartTime);
      event.eventEndTime = getStringTime(event.eventEndTime);

      console.log("Event = , event");
      const result = eventService.insertPrivEvent(event);
      console.log("call eventService.insertPrivEvent() result: ", result);

      recs.push(event);
      console.log("New event list: ", recs);
    // }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    // Set the new records to the new list of guests to trigger the
    // refresh of the component
    setRecords(recs);
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const onCreateEvent = () => {
    setOpenPopup(true);
    setRecordForEdit(null);
    setNewEventID(UUIDGeneratorBrowser());
  }

  const onFilterEvent = () => {
    console.log("Filter");
    setOpenUnderConstructionPopup(true);
  }

  const onSortEvent = () => {
    console.log("Sort");
    setOpenUnderConstructionPopup(true);
  }

  const onShowMyTickets = () => {
    console.log("Show My Tickets");
    setOpenUnderConstructionPopup(true);
  }

  const onShowChat = () => {
    console.log("Show Chat");
    navigate(`/chat`);
  }

  function getStringDate(v) {
    console.log("getStringDate() date (in): ", v);
    const d = new Date(v);
    if ( v === null) {
      return null;
    }

    console.log("getStringDate() new date:", d);

    var mm = d.getMonth() + 1; // getMonth() is zero-based
    var dd = d.getDate();

    return [
      d.getFullYear(),
      (mm > 9 ? "/" : "/0") + mm,
      (dd > 9 ? "/" : "/0") + dd,
    ].join("");
  }

  function getStringTime(v) {
    console.log("getStringTime() date (in):", v);
    const d = new Date(v);
    if ( v === null) {
      return null;
    }

    console.log("getStringTime() new date:", d);

    var HH = d.getHours() + 1; // getHours() is zero-based
    var MM = d.getMinutes();

    const result =
      [(HH > 9 ? "" : "0") + HH, (MM > 9 ? "" : "0") + MM, "00"].join(":") +
      ".000";

    console.log("getStringTime(): ", result);
    return result;
  }

  return (
    <span>
      <Header showSearchField={true}
        userID={props.userID} 
        userName={props.userName} userEmail={props.userEmail} 
        userPictureUrl={props.userPictureUrl}
         {...props}
         setQuery={setQueryString}/>

        <br></br><br></br>

      <span className="event-list-body">
      {!isLoading && isEmpty && (
        <Box>
          <LoadingPage message="Your Private Event List is Empty" />
        </Box>
      )}
      {isLoading && <LoadingPage message="Loading Active Events..." />}
      {!isLoading && !isEmpty && (
        <ul>
          {sortedEvents.map((evt) => (
            <li key={evt.eventID}>
              <PrivateEventCard key={evt.eventID} {...evt} {...props} />
            </li>
          ))}
        </ul>
      )}
      </span>

      <BottomBar
        showCreate="1"
        showFilter="1"
        showSort="1"
        showMyTickets="1"
        showChat="1"
        onCreate={onCreateEvent}
        onFilter={onFilterEvent}
        onSort={onSortEvent}
        onShowMyTickets={onShowMyTickets}
        onShowChat={onShowChat}
      />

      <Popup
        title="Create Event Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <EventAddForm 
          recordForEdit={recordForEdit} 
          addOrEdit={addOrEdit}
          newEventID={newEventID} 
          currentTimeZone={currentTimeZone}
          currentLocale={currentLocale}/>
      </Popup>

      <Popup
        title="Under Construction"
        openPopup={openUnderConstructionPopup}
        setOpenPopup={setOpenUnderConstructionPopup}
      >
        <UnderConstruction 
          description=""
          currentTimeZone={currentTimeZone}
          currentLocale={currentLocale}/>
      </Popup>

    </span>
  );
};

export default PrivateEventsList;

import * as React from "react";
import { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Container, ListItemButton } from "@mui/material";
import Divider from "@mui/material/Divider";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { styled } from "@mui/material/styles";
import Popup from "../../../UI/popup";
import AddressForm from "../../Common/AddressForm";
import FestivalOutlinedIcon from "@mui/icons-material/FestivalOutlined";
import EventDateTimesForm from "./EventDateTimesForm";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const EventDetailsVenue = (props) => {
  const [address, setAddress] = useState({});
  const [openAddressPopup, setOpenAddressPopup] = useState(false);
  const [venue, setVenue] = useState({});
  const [openVenuePopup, setOpenVenuePopup] = useState(false);

  const [eventDateTime, setEventDateTime] = useState({
    eventStartDateTimeUTC: props.event.eventStartDateTimeUTC,
    eventEndDateTimeUTC: props.event.eventEndDateTimeUTC,
    eventDate: props.event.eventDate,
    eventStartTime: props.event.eventStartTime,
    eventEndTime: props.event.eventEndTime,
    // eventTimeZone: props.event.eventTimeZone,
  });
  const [openDateTimePopup, setOpenDateTimePopup] = useState(false);

  const currentAddress = {
    addressString1: props.event.venueAddressString1,
    addressString2: props.event.venueAddressString2,
    city: props.event.venueCity,
    postalCode: props.event.venuePostalCode,
    metroArea: props.event.venueMetroArea,
    province: props.event.venueProvince,
    country: props.event.venueCountry,
    countryShort: props.event.venueCountryShort,
    fullString: props.event.venueAddress,
    formatted_address: props.event.venueAddress,
    coordinates: props.event.venueCoordinates,
    timeZone: props.event.venueTimeZone,
  };
  const currentVenueAddress = {
    venueName: props.event.venueName,
    addressString1: props.event.venueAddressString1,
    addressString2: props.event.venueAddressString2,
    city: props.event.venueCity,
    postalCode: props.event.venuePostalCode,
    metroArea: props.event.venueMetroArea,
    province: props.event.venueProvince,
    country: props.event.venueCountry,
    countryShort: props.event.venueCountryShort,
    fullString: props.event.venueAddress,
    formatted_address: props.event.venueAddress,
    coordinates: props.event.venueCoordinates,
    VenueTimeZone: props.event.venueTimeZone,
  };
  console.log("currentAddress = ", currentAddress);
  console.log("Event address = ", address);

  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const eventDate = props.event.eventDate;
  const isCoHost = props.isCoHost;

  console.log("Props = ", props);

  return (
    <Container disableGutters>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {isCoHost && (
          <ListItemButton
            alignItems="flex-start"
            key="EventDetailsVenue.01"
            onClick={() => {
              setOpenVenuePopup(true);
            }}
          >
            <ListItemIcon>
              <FestivalOutlinedIcon alt="Venue" />
            </ListItemIcon>
            <ListItemText>{currentVenueAddress.venueName}</ListItemText>
          </ListItemButton>
        )}
        {isCoHost && (
          <ListItemButton
            alignItems="flex-start"
            key="EventDetailsVenue.02"
            onClick={() => {
              setOpenAddressPopup(true);
            }}
          >
            <ListItemIcon>
              <LocationOnOutlinedIcon alt="Address" />
            </ListItemIcon>
            {address.fullString === undefined && (
              <ListItemText>
                {currentAddress.fullString}
                <p>{currentAddress.addressString2}</p>
              </ListItemText>
            )}
            {address.fullString !== undefined && (
              <ListItemText>
                {address.fullString}
                <p>{address.addressString2}</p>
              </ListItemText>
            )}
          </ListItemButton>
        )}
        {!isCoHost && (
          <ListItem alignItems="flex-start" key="EventDetailsVenue.03">
            <ListItemIcon>
              <FestivalOutlinedIcon alt="Venue" />
            </ListItemIcon>
            <ListItemText>{currentVenueAddress.venueName}</ListItemText>
          </ListItem>
        )}
        {!isCoHost && (
          <ListItem alignItems="flex-start" key="EventDetailsVenue.04">
            <ListItemIcon>
              <LocationOnOutlinedIcon alt="Address" />
            </ListItemIcon>
            <ListItemText>
              {currentAddress.fullString}
              <p>{currentAddress.addressString2}</p>
            </ListItemText>
          </ListItem>
        )}
      </List>

      <Popup
        title="Event Address"
        openPopup={openAddressPopup}
        setOpenPopup={setOpenAddressPopup}
      >
        <AddressForm
          address={currentAddress}
          setAddress={setAddress}
          entityID={props.event.eventID}
          entityName={"event"}
          setOpenPopup={setOpenAddressPopup}
        />
      </Popup>
    </Container>
  );
};

export default EventDetailsVenue;

import axios from "axios";
import Constants from "./Constants";
import * as memberService from "./memberService";

const guestRESTUrl = Constants.APIUrlString.Guests;
const memberRESTUrl = Constants.APIUrlString.Members;
const separator = Constants.Separator;

const httpRequest = async (url) => {
  const response = await axios.get(url);
  console.log("httpRequest response: ", response.data);
  return response.data;
};

async function getAllGuestsAsync(queryKey, queryValue) {
  var url =
    guestRESTUrl +
    "/guests" +
    "?queryKey=" +
    queryKey +
    "&queryValue=" +
    queryValue;
  console.log("getAllGuests URL: ", url);

  const res = await axios.get(url);
  var guests = [];
  console.log("getAllGuests guests: ", res.data);
  // Set guestResponse to "Not Sure" if it is null
  res.data.forEach((guest) => {
    if (!guest.hasOwnProperty("guestResponse")) {
      guest.guestResponse = "Not Sure";
    } else if (guest.guestResponse === null) {
      guest.guestResponse = "Not Sure";
    }
    guests.push(guest);
  });
  return guests;
}

/*
** Get a member by email
*/
async function getMemberByEmail(memberEmail) {
  const resp = axios.get(
    memberRESTUrl +
      "/members" +
      "?queryKey=memberEmail&queryValue=" +
      memberEmail.toLowerCase()
  );
  resp.then((res) => {
    const members = res.data;
    console.log("Members are: ", members);

    if (members.length > 0) {
      return members[0];
    }
    return null;
  });
  resp.catch((error) => {
    console.log(error);
    throw error;
  });
  return resp;
}

/*
** Get a guest in this event by email
*/  
export async function getGuestByEmail(eventID, memberEmail) {
  const resp = axios.get(
    memberRESTUrl +
      "/guests" +
      "?queryKey=eventID~memberEmail&queryValue=" + eventID + "~" +
      memberEmail.toLowerCase()
  );
  resp.then((res) => {
    const guests = res.data;
    console.log("Guests are: ", guests);

    if (guests.length > 0) {
      return true;
    }
    return false;
  });
  resp.catch((error) => {
    console.log(error);
    throw error;
  });
  return resp;
}

/*
** Get a guest in this event by memberID
*/  
export async function getGuestByMemberID(eventID, memberID) {
  const resp = axios.get(
    memberRESTUrl +
      "/guests" +
      "?queryKey=eventID~memberID&queryValue=" + 
      eventID + "~" + memberID
  );
  resp.then((res) => {
    const guests = res.data;
    console.log("Guests are: ", guests);

    if (guests.length > 0) {
      return true;
    }
    return false;
  });
  resp.catch((error) => {
    console.log(error);
    throw error;
  });
  return resp;
}

export async function insertGuest(data) {
  console.log("insertGuest() - data: ", data);
  // Get the member's data
  var guest = data;
  guest.memberEmail = data.memberEmail.toLowerCase();

  // Get the member which email have been provided if he exists
  const membersByEmail = await getMemberByEmail(guest.memberEmail);
  console.log("Existing member: ", membersByEmail);
  const existingMember = membersByEmail.data[0];

  if (existingMember.memberID !== null) {
    guest.memberEmail = existingMember.memberEmail.toLowerCase();
    guest.memberID = existingMember.memberID;
    guest.memberPictureUrl = existingMember.memberPictureUrl;
    if (
      existingMember.hasOwnProperty("preferredTimeZone") &&
      existingMember.preferredTimeZone !== "undefined"
    ) {
      guest.preferredTimeZone = existingMember.preferredTimeZone;
    }
  } else {
    // Insert the member
    const newMember = await memberService.insertMember({
      memberName: guest.memberName,
      memberFirstName: guest.memberFirstName,
      memberLastName: guest.memberLastName,
      memberEmail: guest.memberEmail,
      memberPhone: guest.memberPhone,
    });
    console.log("New member created: ", newMember);
    guest.memberID = newMember.memberID;
  }

  const url = guestRESTUrl + "/guest";
  console.log("guestService.insertGuest: ", guest);
  console.log("REST API Url: ", url);

  //   axios.post(guestRESTUrl, data, headers ).
  const newGuest = await axios.post(url, guest)
    .then((res) => {
      console.log("guestService.insertGuest return: ", res);
      //return res.data.Item;
    })
    .catch((error) => {
      console.log(error);
    });
  //return newGuest;
}

export function updateGuest(data) {
  console.log("guestService.updateGuest: ", data);
}

export function getAllGuests(queryKey, queryValue) {
  return getAllGuestsAsync(queryKey, queryValue);
}

export function updateRsvp(data) {
  console.log("guestService.updateRsvp: ", data);
  // Save the the guest in history before update
  insertGuestHistory(data, "Update");

  // Update the response
  const updateKeys =
    "accepted" +
    separator +
    "declined" +
    separator +
    "notSure" +
    separator +
    "guestResponse" +
    (data.guestComments !== null ? separator + "guestComments" : "") +
    (data.memberPictureUrl !== null ? separator + "memberPictureUrl" : "");

  const updateValues =
    data.accepted +
    separator +
    data.declined +
    separator +
    data.notSure +
    separator +
    data.guestResponse +
    (data.guestComments !== null ? separator + data.guestComments : "") +
    (data.memberPictureUrl !== null ? separator + data.memberPictureUrl : "");

  console.log("UpdateKeys: ", updateKeys);
  console.log("UpdateValues: ", updateValues);
  return updateAttributes(data.guestID, updateKeys, updateValues);
}

export function updateDeadLine(data) {
  console.log("guestService.updateDeadLine: ", data);
  // Save the the guest in history before update
  insertGuestHistory(data, "Update");

  // Update the response
  const updateKeys = "deadLineDateTime";
  const updateValues = "" + data.deadLineDateTime + "";

  console.log("UpdateKeys: ", updateKeys);
  console.log("UpdateValues: ", updateValues);
  const updateResponse = updateAttributes(
    data.guestID,
    updateKeys,
    updateValues
  );
  // Send a reminder
  console.log("Sending a reminder to: ", data.memberName);
  sendReminder(data);
}

// Update a single attribute??
function updateAttribute(guestID, updateKey, updateValue) {
  var data = {
    guestID: guestID,
    updateKey: updateKey,
    updateValue: updateValue,
  };
  const url = guestRESTUrl + "/guest";
  console.log("guestService.updateAttribute() - URL: ", url);
  console.log("guestService.updateAttribute() - Data: ", data);
  // Call the REST API
  // TODO - better error handling using validateStatus
  axios
    .patch(url, data)
    .then((res) => console.log("Ok - response: ", res))
    .catch((err) => console.error("Failed: ", err));
}

// Update multiple attributes
function updateAttributes(guestID, updateKeys, updateValues) {
  var data = {
    guestID: guestID,
    updateKey: updateKeys,
    updateValue: updateValues,
  };
  const url = guestRESTUrl + "/guest";
  console.log("guestService.updateAttributes() - URL: ", url);
  console.log("guestService.updateAttributes() - Data: ", data);
  // Call the REST API
  axios
    .patch(url, data)
    .then((res) => console.log("Ok - response: ", res))
    .catch((err) => console.error("Failed: ", err));
}

export function insertGuestHistory(data, action = "Update") {
  // Get the member's data

  console.log("guestService.insertGuestHistory: ", data);

  // Read the data first, then insert them into history before updating
  console.log(
    "read the guest record: ",
    guestRESTUrl + "/guest?guestID=" + data.guestID
  );
  axios
    .get(guestRESTUrl + "/guest?guestID=" + data.guestID)
    .then((res) => {
      var hist = res.data;
      hist.historyAction = action;
      hist.historyDateTime = Date.now();
      hist.guestHistoryID = "";
      console.log("posting the history data: ", hist);
      axios
        .post(guestRESTUrl + "/guest_history", hist)
        .then((res) => {
          console.log(
            "guestService.insertGuestHistory return: ",
            res.data.Item
          );
          return res.data.Item;
        })
        .catch((error) => {
          console.error(error);
        });
    })
    .catch((error) => {
      console.error(error);
    });
}

export function sendReminder(guest) {
  var postData = {
    notificationAction: "rsvpReminder",
    notificationData: guest,
  };
  const url = guestRESTUrl + "/guest/notification/request";
  console.log("guestService.sendReminder() - URL: ", url);
  console.log("guestService.sendReminder() - Data: ", postData);
  // Call the REST API
  axios
    .post(url, postData)
    .then((res) => console.log("Ok - response: ", res))
    .catch((err) => console.error("Failed: ", err));
}

export async function deleteGuest(guest) {
  
  const url = guestRESTUrl + "/guest";
  console.log("Deleting guest: ", guest);

  const result = await axios.delete(url, {params: {guestID: guest.guestID}})
    .then((res) => {
      console.log("Deleted successfully: ", res);
    })
    .catch((error) => {
      //console.error("Delete guest: ", guest);
      //console.error("Delete failed :", error);
      throw error;
    })
}
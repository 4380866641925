import axios from "axios";
import Constants from "./Constants";

const memberRESTUrl = Constants.APIUrlString.Members;

const httpRequest = async (url) => {
  const response = await axios.get(url);
  console.log("httpRequest response: ", response.data);
  return response.data;
};

/*
Get all members asynchronously
*/
async function getAllMembersAsync(queryKey, queryValue) {
  const RESTUrl =
    memberRESTUrl +
    "/members" +
    "?queryKey=" +
    queryKey +
    "&queryValue=" +
    queryValue;
  console.log("getAllMembers URL: ", RESTUrl);

  const res = await axios.get(RESTUrl);
  console.log("getAllMembersAsync members[0]: ", res.data[0]);
  return res.data;
}

export function getAllMembers(queryKey, queryValue) {
  return getAllMembersAsync(queryKey, queryValue);
}

export function updateMember(member) {
  const separator = "~";
  const url = memberRESTUrl + "/member";
  console.log("memberService.updateMember: ", member);
  let sUpdateKeys = "";
  let sUpdateValues = "";
  let tSeparator = "";

  for (const key in member) {
    if (
      member.hasOwnProperty(key) &&
      key !== "memberID" &&
      key !== "coordinates"
    ) {
      console.log(`${key} : ${member[key]}`);
      sUpdateKeys = sUpdateKeys + tSeparator + key;
      sUpdateValues = sUpdateValues + tSeparator + member[key];
      tSeparator = separator;
    }
  }
  console.log("sUpdateKeys = ", sUpdateKeys);
  console.log("sUpdateValues = ", sUpdateValues);

  // update all attributes bu Coordinate object
  updateAttributes(member.memberID, sUpdateKeys, sUpdateValues);

  // Update Coordinate attribute if specified
  if (member.hasOwnProperty("coordinates")) {
    updateAttribute(member.memberID, "coordinates", member.coordinates, true);
  }
}

export function updateMemberAddress(memberID, address) {
  const url = memberRESTUrl + "/member";
  const separator = "~";

  console.log("memberService.updateMemberAddress: ", memberID, address);
  updateAttributes(
    memberID,
    "addressString1" +
      separator +
      "addressString2" +
      separator +
      "city" +
      separator +
      "postalCode" +
      separator +
      "province" +
      separator +
      "metroArea" +
      separator +
      "country" +
      separator +
      "countryShort" +
      separator +
      "fullAddress",
    address.addressString1 +
      separator +
      (typeof address.addressString2 === "undefined"
        ? " "
        : address.addressString2) +
      separator +
      address.city +
      separator +
      address.postalCode +
      separator +
      address.province +
      separator +
      address.metroArea +
      separator +
      address.country +
      separator +
      address.countryShort +
      separator +
      address.formatted_address
  );

  // Update the Coordinates
  updateAttribute(
    memberID,
    "coordinates",
    JSON.stringify(address.coordinates),
    true
  );
}

// Update a single member attribute
function updateAttribute(memberID, updateKey, updateValue, isJSON = false) {
  var data = {
    memberID: memberID,
    updateKey: updateKey,
    updateValue: updateValue,
    isJSON: isJSON,
  };
  const url = memberRESTUrl + "/member";
  console.log("memberService.updateAttribute() - URL: ", url);
  console.log("memberService.updateAttribute() - Data: ", data);
  // Call the REST API
  axios
    .patch(url, data)
    .then((res) => console.log("Ok - response: ", res))
    .catch((err) => console.error("Failed: ", err));
}

// Update mutilple attribites simultaneously
export function updateAttributes(memberID, updateKey, updateValue) {
  var data = {
    memberID: memberID,
    updateKey: updateKey,
    updateValue: updateValue,
  };
  const url = memberRESTUrl + "/member";
  console.log("memberService.updateAttribute() - URL: ", url);
  console.log("memberService.updateAttribute() - Data: ", data);
  // Call the REST API
  axios
    .patch(url, data)
    .then((res) => console.log("Ok - response: ", res))
    .catch((err) => console.error("Failed: ", err));
}

// Get a member by email
async function getMemberByEmail(email) {
  const RESTUrl =
    memberRESTUrl +
    "/members" +
    "?queryKey=memberEmail&queryValue=" +
    email.toLowerCase();
  console.log("getMemberByEmail URL: ", RESTUrl);

  const res = await axios.get(RESTUrl);
  console.log("getMemberByEmail 1st member: ", res.data[0]);
  return res.data[0];
}

// Get a member by ID
export async function getMemberByID(memberID) {
  const RESTUrl =
    memberRESTUrl + "/members" + "?queryKey=memberID&queryValue=" + memberID;
  console.log("getMemberByID URL: ", RESTUrl);

  const res = await axios.get(RESTUrl);
  console.log("getMemberByID member: ", res.data);
  return res.data;
}

async function createMember(data) {
  console.log("createMember() - data: ", data);
  // Get the member's data
  var member = data;
  if (!member.hasOwnProperty("country")) {
    member.country = "Canada"
  }
  if (!member.hasOwnProperty("memberID")) {
    member.memberID = ""
  }
  member.memberEmail = data.memberEmail.toLowerCase();
  const url = memberRESTUrl + "/member";

  //console.log("memberService.createMember: ", member);
  console.log("REST API Url: ", url);

  //   axios.post(memberRESTUrl, data, headers ).
  const memberCreated = await axios.post(url, member)
    .then((res) => {
      const result = res.data.Item;
      console.log("memberService.createMember return: ", result);
      return result;
    })
    .catch((error) => {
      console.log(error);
    });

  // Final return
  return memberCreated;
}

export async function insertMember(member) {
  // If a memberID is present, returns an error
  if (member.hasOwnProperty("memberID") && member.memberID !== "") {
    return member;
  }
  // Find the member using
  const existingMember = await getMemberByEmail(member.memberEmail);
  if (existingMember !== undefined && existingMember !== null) {
    return existingMember;
  } else {
    // Create a new member
    const newMember = await createMember(member);
    console.log("insertMember - newMember = ", newMember);
    return newMember;
  }

}

/*
 ** Function: buildResponse
 ** Purpose: format  error code/message
 */
function buildResponse(statusCode, body) {
  return {
    statusCode: statusCode,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
}

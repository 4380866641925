import React from "react";

const UnderConstruction = () => {
    return (
        <div>
            <img src="https://genzab-content183420-dev.s3.us-west-2.amazonaws.com/public/common/default/funny-under-construction-simpson.gif"
            height={300} width={300}>
            </img>
        </div>
    )
}

export default UnderConstruction;
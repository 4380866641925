import React from "react";
import { Grid } from "@material-ui/core";

import EventDetailsHeader from "./EventDetailsHeader";
import EventDetailsBody from "./EventDetailsBody";
import EventDetailsTabsPanel from "./EventDetailsTabsPanel";
import { Box, Container } from "@mui/material";

const EventDetailsPage = (props) => {
  return (
    <Container disableGutters>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <EventDetailsHeader {...props} />
          <EventDetailsTabsPanel {...props} />
        </Grid>
      </Box>
    </Container>
  );
};

export default EventDetailsPage;

import React, { useState, useEffect } from "react";
import { Grid, Box } from "@material-ui/core";
import Controls from "../../UI/controls/Controls";
import { useForm, Form } from "../../UI/useForm";

import {
  Paper,
  makeStyles,
} from "@material-ui/core";

const guestResponseItems = [
  { id: "accepted", title: "Accept" },
  { id: "declined", title: "Decline" },
  { id: "notsure", title: "I'm Not Sure" },
];
/* 
const initialFValues = {
  guestID: "",
  guestComments: "",
  guestResponse: "accepted",
  memberID: "",
  memberPictureUrl: "",
};
 */
export default function GuestRsvpForm(props) {
  const useStyles = makeStyles((theme) => ({
    pageContent: {
      margin: theme.spacing(5),
      padding: theme.spacing(0),
      width: "100%",
    },
    searchInput: {
      width: "75%",
    },
    newButton: {
      position: "absolute",
      right: "10px",
    },
  }));


const initialFValues = {
  guestID: "",
  guestComments: "",
  guestResponse: "accepted",
  memberID: props.userID,
  memberPictureUrl: props.userPictureUrl,
};

  const classes = useStyles();

  const { addOrEdit, recordForEdit, eventName, eventOwner, guestID, userID } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("guestComments" in fieldValues)
      temp.guestComments = 
        fieldValues.guestComments.length < 255
        ? ""
        : "Comments cannot exceed 255 characteres.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  return (
    <Paper className={classes.pageContent}>
      <Form onSubmit={handleSubmit}>
        <Grid container>
        <Grid item xs={12}>
          {eventName} <br/>
          {" organized by "}{eventOwner}
        </Grid>
          <Grid item xs={12}>
          <Controls.Select
              name="guestResponse"
              label="Check your response"
              value={values.guestResponse}
              onChange={handleInputChange}
              // items={guestResponseItems}
              options={guestResponseItems}
            />
            <Controls.InputMulti
              name="guestComments"
              label="Comments (visible ONLY by host(s))"
              value={values.guestComments}
              onChange={handleInputChange}
              error={errors.guestComments}
              maxRows={5}
            />
            <span>
              <Controls.Button type="submit" text="Submit" />
            </span>
          </Grid>
        </Grid>
      </Form>
    </Paper>
  );
}

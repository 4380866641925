import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import ChatHeader from "./ChatHeader";
import ChatEventCard from "./ChatEventCard";
import Avatar from "@mui/material/Avatar";
import ChatEventConversation from "./ChatEventConversation";
import Header from "../../UI/Header";

export default function ChatEventsList(props) {
  // Current event
  const [targetEvent, setTargetEvent] = useState({ eventID: "" });
  const [message, setMessage] = useState();
  const [navigateBackToList, setNavigateBackToList] = useState(false);
  const messagesEndRef = useRef(null);

  const messages = props.messages;
  const targetEventID = props.targetEventID;
  const currentMember = props.member;
  const connections = props.connections;
  const events = props.events;
  const url = props.url;

  useEffect(() => {
    if (targetEventID !== undefined && targetEventID !== null) {
      setTargetEvent({
        eventID: targetEventID,
        eventName: "TODO read it from DB",
        eventStartDateTimeUTC: 1686172391358,
      });
      setNavigateBackToList(false);
    }
  }, [targetEventID]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const submit = () => {
    setMessage("");
    props.sendMessage(message);
    scrollToBottom();
  };

  const handleOnClickEvent = (e) => {
    console.log("handleOnClickEvent() - e: ", e);
    props.setTargetEventID(e.eventID);
    props.loadMessages(e.eventID);
    setTargetEvent(e);
    setNavigateBackToList(true);
  };

  const handleRefresh = () => {
    if (targetEvent.eventID === "") {
      props.loadMyEvents();
    } else {
      console.log(
        "Refreshing the messages from eventID: ",
        targetEvent.eventID
      );
      props.setTargetEventID(targetEvent.eventID);
      props.loadMessages();
      console.log("Messages: ", messages);
    }
  };

  console.log("Target event: ", targetEvent);

  return (
    <div>
      <div className="flex-none border-r-2 border-gray-200 w-20 md:w-64">
        {targetEvent.eventID === "" && (
          <div className="flex flex-col px-2">
            {/* <ChatHeader title="Chats list" refresh={handleRefresh} /> */}
            <Header
              showSearchField={false}
              userID={props.member.memberID}
              userName={props.member.memberName}
              userEmail={props.member.memberEmail}
              userPictureUrl={props.member.memberPictureUrl}
              member={props.member}
              title="Available Chats List"
              subTitle=""
            />
      <br></br><br></br>

            <div className="flex flex-col chat-vent-list">
              {events.length < 1 && <div><br/>No event to show</div>}
              {events.map((event, key) => (
                <div key={key}>
                  <ChatEventCard className="chat-event-card"
                    eventName={event.eventName}
                    venueAddress={event.venueAddress}
                    eventStartDateTimeUTC={event.eventStartDateTimeUTC}
                    eventOwnerPictureUrl={event.eventOwnerPictureUrl}
                    eventOwner={event.eventOwner}
                    eventID={event.eventID}
                    venueTimeZone={event.venueTimeZone}
                    setTargetEvent={setTargetEvent}
                    event={event}
                    onEventClick={() => {
                      handleOnClickEvent(event);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {targetEvent.eventID !== "" && targetEvent.eventID !== undefined && (
          <div className="flex-1 p:2 sm:p-6 justify-between flex flex-col h-screen">
            <ChatEventConversation
              member={currentMember}
              connections={connections}
              event={targetEvent}
              targetEventID={targetEvent.eventID}
              messages={messages}
              url={url}
              loadMessages={props.loadMessages}
              loadMyEvents={props.loadMyEvents}
              setTargetEventID={props.setTargetEventID}
              sendMessage={props.sendMessage}
              sendTyping={props.sendTyping}
              typingConnections={props.typingConnections}
              setTypingConnections={props.setTypingConnections}
              someOneIsTyping={props.someOneIsTyping}
              setSomeOneIsTyping={props.setSomeOneIsTyping}
              navigateBackToList={navigateBackToList}
              setTargetEvent={setTargetEvent}
              />
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Button, Grid, Tooltip } from "@material-ui/core";
import Controls from "../../../UI/controls/Controls";
import { useForm, Form } from "../../../UI/useForm";
import Popup from "../../../UI/popup";
import { updateEventDates } from "../../../Services/eventService";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";

const { DateTime } = require("luxon");

const EventDateTimesForm = (props) => {

  const venueTimeZone = props.venueTimeZone;
  const currentLocale = props.currentLocale !== undefined ? props.currentLocale : new Intl.DateTimeFormat().resolvedOptions().locale;

  const initialFValues = {
    eventID: props.eventDateTime.eventID,
    eventStartDateTimeUTC: props.eventDateTime.eventStartDateTimeUTC,
    eventEndDateTimeUTC: props.eventDateTime.eventEndDateTimeUTC,
    eventDate: utcToZonedTime(
      Math.floor(props.eventDateTime.eventStartDateTimeUTC), venueTimeZone
    ),
    eventStartTime: utcToZonedTime(
      Math.floor(props.eventDateTime.eventStartDateTimeUTC), venueTimeZone
    ),
    eventEndTime: utcToZonedTime(
      Math.floor(props.eventDateTime.eventEndDateTimeUTC), venueTimeZone),
    // eventTimeZone: props.event.eventTimeZone,
  };
  console.log("Initial values: ", initialFValues);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("eventDate" in fieldValues)
      temp.eventDate = fieldValues.eventDate ? "" : "Event date is required.";
    if ("eventStartTime" in fieldValues)
      temp.eventStartTime = fieldValues.eventStartTime
        ? ""
        : "Event start time is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  function addOrEdit(record, resetFunction) {
    console.log("handle submit - record = ", record);
    updateEventDates(
      record.eventID,
      record.eventStartDateTimeUTC,
      record.eventEndDateTimeUTC,
      record.timeZone,
      record.eventDate,
      record.eventStartTime,
      record.eventEndTime
    );
    // CLose the popup
    props.setOpenPopup(false);
    props.setDateTime(record);
  }

  const handleSubmit = (e) => {
    console.log("handle submit UI event = ", e);
    console.log("Values - ", values);
    const newEventDate = new Date(values.eventDate);
    const newStartTime = new Date(values.eventStartTime);
    const newEndTime = new Date(values.eventEndTime);

    values.eventStartDateTimeUTC = DateTime.fromObject(
      {
        year: newEventDate.getFullYear(),
        month: newEventDate.getMonth(),
        day: newEventDate.getDate(),
        hour: newStartTime.getHours(),
        minute: newStartTime.getMinutes(),
      },
      { zone: venueTimeZone }
    ).toUTC();

    values.eventEndDateTimeUTC =
      values.eventEndTime === ""
        ? ""
        : DateTime.fromObject(
          {
            year: newEventDate.getFullYear(),
            month: newEventDate.getMonth(),
            day: newEventDate.getDate(),
            hour: newEndTime.getHours(),
            minute: newEndTime.getMinutes(),
          },
          { zone: venueTimeZone }
        ).toUTC();
        
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  return (
    <div>
      <Controls.Input
        disabled={true}
        name="venueTimeZone"
        label="Venue Time Zone"
        value={venueTimeZone}
      />
      <br />
      <br />
      <Controls.MyDatePicker
        name="eventDate"
        label="Event date"
        value={values.eventDate}
        onChange={handleInputChange}
        timeZone={venueTimeZone}
        locale={currentLocale}
      />
      <br />
      <br />
      <Controls.MyTimePicker
        name="eventStartTime"
        value={values.eventStartTime}
        label="Event Start Time"
        onChange={handleInputChange}
        timeZone={venueTimeZone}
        locale={currentLocale}
  />
      <br />
      <br />
      <Controls.MyTimePicker
        name="eventEndTime"
        value={values.eventEndTime}
        label="Event End Time"
        onChange={handleInputChange}
        timeZone={venueTimeZone}
        locale={currentLocale}
      />
      <div>
        <Controls.Button text="Submit" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default EventDateTimesForm;

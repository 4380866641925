import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

function createData(question, answer) {
  return { question, answer };
}

const rows = [
  createData('Is there a safe parking at the venue?', 
  'Yes, there is a public parking at 250 to 500 m down the street'),
  createData('Can we buy some water near the venue at a reasonable price?', 
  'Yes, there is Walmart at 1.5 to 2 km'),
  createData('How long does the party last?', 'We have around 1h and 30 min at the venue. But after that we can continue at a bar or a night club'),
  createData('Are kids allowed?', 'No, unfortunately, kids are not recommended because of the noise'),
  createData('Are pets allowed?', 'No, pets are not allowed because of the high level of noise'),
  createData('Can I bring my own booze?', 'No'),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

const EventFAQ = (props) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">QuestionMarkOutlined</StyledTableCell>
            <StyledTableCell align="left">Answer</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.question}
              sx={{ '&:last-child td, &:last-child th': { border: "none" } }}
            >
              <TableCell component="th" scope="row" align="left">
                {row.question}
              </TableCell>
              <TableCell align="left">{row.answer}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


export default EventFAQ; 
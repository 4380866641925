export class WebSocketConnector {
    private connection?: WebSocket;
    private url?: string;
  
    getConnection(url: string): WebSocket {
      
      console.log("WebSocketConnector() - getCOnnection() URL: ", url);
      //
      if (url !== this.url) {
        console.log("\t . WebSocketConnector() - new URL: ", url);
        if (this.connection) {
          this.connection.close();
        }
  
        this.url = url;
        this.connection = new WebSocket(this.url);
      }
      else {
        console.log("\t . WebSocketConnector() - exsisting URL - connection: ", this.connection);
         if ((this.connection?.readyState === 2) || (this.connection?.readyState === 3)) {
          console.log("\t .Connection CLOSED - creating new one");
          this.connection = new WebSocket(this.url);
        }
      }
  
      return this.connection as WebSocket;
    }

  }
import * as React from "react";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import {
  Button,
  Grid,
  Tooltip,
  Box,
  ButtonBase,
  Container,
} from "@material-ui/core";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import * as eventService from "../../../Services/eventService";

import "./EventDetails.css";

import EventDetailsVenue from "./EventDetailsVenue";
import EventFAQ from "./EventFAQ";
import EventWhatForm from "./EventWhatForm";
import Popup from "../../../UI/popup";
import { styled } from "@mui/material/styles";
import EventDetailsDateTime from "./EventDetailsDateTime";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const EventDetailsBody = (props) => {
  // Can edit the event if the owner is the logged in user
  console.log("Props = ", props);
  const isOwner = props.userID === props.ownerID;
  const coHosts = props.hasOwnProperty("coHosts") ? props.coHosts : [{}];
  const currentTimeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentLocale = props.currentLocale;
  const isCoHost = isOwner || isInCoHostList(props.userID, coHosts);
  console.log("isOwner = ", isOwner);
  console.log("isCoHost = ", isCoHost);

  function isInCoHostList(item, items) {
    return items.includes(item);
  }
  const eventWhat = {
    eventID: props.event.eventID,
    eventType: props.event.eventType,
    eventName: props.event.eventName,
    eventDescription: props.event.eventDescription,
    venueImageUrl: props.event.venueImageUrl,
  };
  // Handling What Event form
  const [recordWhatForEdit, setRecordWhatForEdit] = useState(null);
  const [openWhatPopup, setOpenWhatPopup] = useState(false);
  const [whatRecord, setWhatRecord] = useState(eventWhat);
  console.log("whatRecord = ", whatRecord);
  console.log("eventWhat = ", eventWhat);

  const addOrEditWhat = (what, resetForm) => {
    if (what.eventID !== "")
      eventService.updateEventWhat(
        what.eventID,
        what.eventType,
        what.eventName,
        what.eventDescription,
        what.venueImageUrl
      );
    resetForm();
    setRecordWhatForEdit(null);
    setOpenWhatPopup(false);
    setWhatRecord(what);
  };
  const openInPopupWhat = (item) => {
    setRecordWhatForEdit(item);
    setOpenWhatPopup(true);
  };

  return (
    <Container disableGutters>
      <Box>
        <Accordion className="accordion" defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandCircleDownOutlinedIcon />}
            aria-controls="panel2a-content"
            id="panel1a-header"
          >
            <Typography sx={{ fontStyle: "italic", fontWeight: "bold" }}>
              What?
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            {whatRecord.venueImageUrl === undefined && (
              <Img className="event-detail-image" src={eventWhat.venueImageUrl} />
            )}
            {whatRecord.venueImageUrl !== undefined && (
              <Img className="event-detail-image" src={whatRecord.venueImageUrl} />
            )}
            
            <br/>
            { whatRecord.eventName === undefined && (
              <Typography>
                <p className="event-details-title">{eventWhat.eventName}</p>
              </Typography>
            )}
            { whatRecord.eventName !== undefined && (
              <Typography sx={{ textAlign: "justify" }}>
                <p className="event-details-title">{whatRecord.eventName}</p>
              </Typography>
            )}
            { whatRecord.eventDescription === undefined && (
            <Typography>
              <p className="event-detail-description">{eventWhat.eventDescription}</p>
            </Typography>
            )}
            { whatRecord.eventDescription !== undefined && (
            <Typography >
              <p className="event-detail-description">{whatRecord.eventDescription}</p>
            </Typography>
            )}

            {isCoHost && (
              <Tooltip title="Modify Event Info">
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      openInPopupWhat(eventWhat);
                    }}
                  >
                    <EditOutlinedIcon />
                  </button>
                </div>
              </Tooltip>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion className="accordion" defaultExpanded={false}>
          <AccordionSummary
            expandIcon={<ExpandCircleDownOutlinedIcon />}
            aria-controls="panel1a-content"
            id="panel2a-header"
          >
            <Typography sx={{ fontStyle: "italic", fontWeight: "bold" }}>
              Where?
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <EventDetailsVenue isCoHost={isCoHost} currentTimeZone={currentTimeZone} currentLocale={currentLocale} {...props} />
          </AccordionDetails>
        </Accordion>

        <Accordion className="accordion" defaultExpanded={false}>
          <AccordionSummary
            expandIcon={<ExpandCircleDownOutlinedIcon />}
            aria-controls="panel1a-content"
            id="panel3a-header"
          >
            <Typography sx={{ fontStyle: "italic", fontWeight: "bold" }}>
              When?
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <EventDetailsDateTime isCoHost={isCoHost} currentTimeZone={currentTimeZone} currentLocale={currentLocale} {...props} />
          </AccordionDetails>
        </Accordion>

        <Popup
          title="Event What Form"
          openPopup={openWhatPopup}
          setOpenPopup={setOpenWhatPopup}
        >
          <EventWhatForm
            {...props}
            recordForEdit={recordWhatForEdit}
            addOrEdit={addOrEditWhat}
          />
        </Popup>
      </Box>
    </Container>
  );
};

export default EventDetailsBody;

import React from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment-timezone';

export default function DatePicker(props) {

    const { name, label, value, onChange, timeZone, locale } = props
    if ((timeZone !== undefined) && (timeZone !== "")) {
        moment.tz.setDefault(timeZone);
    }

    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}> 
            {/* <KeyboardDatePicker disableToolbar variant="inline" inputVariant="outlined" */}
            <KeyboardDatePicker variant="inline" inputVariant="outlined"
            label={label}
                // format="MMM/dd/yyyy"
                name={name}
                value={value}
                onChange={date =>onChange(convertToDefEventPara(name,date))}

            />
        </MuiPickersUtilsProvider>
    )
}
/* eslint-disable default-case */
import * as React from "react";
import { useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";

import * as guestService from "../../../Services/guestService";
import GuestAddForm from "../../guests/GuestAddForm";
import Popup from "../../../UI/popup";
import GuestRsvpForm from "../../guests/GuestRsvpForm";
import { Box } from "@material-ui/core";
import GuestCard from "../../guests/GuestCard";
import BottomBar from "../../../UI/BottomBar";
import Button from "@material-ui/core/Button";

import ThumbDownTwoToneIcon from "@mui/icons-material/ThumbDownTwoTone";
import ThumbUpTwoToneIcon from "@mui/icons-material/ThumbUpTwoTone";
import PsychologyAltTwoToneIcon from "@mui/icons-material/PsychologyAltTwoTone";

import "./EventDetails.css";
import GuestRsvpPage from "../../guests/GuestRsvpPage";

const EventGuestsTable = (props) => {
  const guests = props.rows;
  console.log("Props = ", props);
  let isEmpty = true;

  if (guests) {
    if (Array.isArray(guests)) {
      isEmpty = guests.length === 0;
    }
  }
/* 
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
 */
  const userID = props.userID;
  const ownerID = props.ownerID;
  const userPictureUrl = props.member.memberPictureUrl;
  const rsvp = {
    guestID: "",
    guestResponse: "accepted",
    guestComments: "",
    memberID: "",
    memberPictureUrl: "",
  };

  // Set openPopup Guest state
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState(props.rows);

  // Add or edit a guest
  const addOrEdit = (guest, resetForm) => {
    var recs = records;

    // TODO - if the member is already in the list, do not add it again
    if (guest.guestID === "") {
      // Set the values of the guest record before inserting the record
      guest.eventID = props.eventID;
      guest.memberName = guest.memberFirstName + " " + guest.memberLastName;
      guest.invitationSentDateTime = Date.now();
      guest.eventName = props.eventName;
      guest.eventOwner =
        props.member.memberFirstName + " " + props.member.memberLastName;
      guest.venueName = props.venueName;
      guest.eventDate = props.eventDate;
      guest.eventStartTime = props.eventStartTime;
      console.log("Before insert, guest record is : ", guest);
      guestService.insertGuest(guest);
      // TODO Get the guestID created
      /* 
      const newGuest = guestService.insertGuest(guest);
      guest.guestID = newGuest.guestID;
 */
      recs.push(guest);
      console.log("New guest list: ", recs);
    } else {
      guestService.updateGuest(guest);
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    // Set the new records to the new list of guests to trigger the
    // refresh of the component
    setRecords(recs);
  };

  // Setup RSVP popup
  const [openRsvpPopup, setOpenRsvpPopup] = useState(false);
  const [recordRsvpForEdit, setRecordRsvpForEdit] = useState(rsvp);
  const [rsvpRecord, setRsvpRecord] = useState(rsvp);
  const [rsvpRecordIdx, setRsvpRecordIdx] = useState(0);

  // Add or edit a guest
  const addOrEditRsvp = (rsvp, resetRsvpForm) => {
    // Set the right response
    console.log("RSVP = ", rsvp);
    switch (rsvp.guestResponse) {
      case "accepted":
        rsvp.accepted = true;
        rsvp.declined = false;
        rsvp.notSure = false;
        break;
      case "declined":
        rsvp.accepted = false;
        rsvp.declined = true;
        rsvp.notSure = false;
        break;
      case "notsure":
        rsvp.accepted = false;
        rsvp.declined = false;
        rsvp.notSure = true;
        break;
    }
    // TODO - send a notification to the event owner
    guestService.updateRsvp(rsvp);
    setRsvpRecord(rsvp);
    resetRsvpForm();
    setRecordRsvpForEdit(null);
    setOpenRsvpPopup(false);

    guests[rsvpRecordIdx] = rsvp;
    setRecords(guests);
  };

  console.log("Guests: ", guests);
  console.log("isEmpty: ", isEmpty);

  const handleAddGuest = () => {
    setOpenPopup(true);
    setRecordForEdit(null);
  };

  return (
    <span>
      <Box alignItems="left" sx={{ maxWidth: 465 }}>
        {!isEmpty && (
          <ul>
            {records.map((guest, idx) => (
              <li key={guest.guestID}>
                <Accordion className="accordion" defaultExpanded={false}>
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={<ExpandCircleDownOutlinedIcon />}
                  >
                    <Typography>
                    {props.member.memberID === guest.memberID && ("(myself) ")}
                      {guest.memberName + "    "}
                      {guest.guestResponse === "accepted" && (
                        <ThumbUpTwoToneIcon />
                      )}
                      {guest.guestResponse === "declined" && (
                        <ThumbDownTwoToneIcon />
                      )}
                      {guest.guestResponse === "notsure" && (
                        <PsychologyAltTwoToneIcon />
                      )}
                    </Typography>
                  </AccordionSummary>
                  {/* const isOwner = props.event.ownerID === props.member.memberID; */}

                  <AccordionDetails>
                    {props.event.ownerID === props.member.memberID && (
                      <GuestCard
                        guest={guest}
                        currentTimeZone={props.currentTimeZone}
                        currentLocale={props.currentLocale}
                        venueTimeZone={props.event.venueTimeZone}
                        member={props.member}
                        guests={records}
                        setGuests={setRecords}
                        eventOwnerID={props.event.ownerID}
                      />
                    )}
                    {props.member.memberID === guest.memberID && (
                      <span>
                        <GuestRsvpPage {...props} guest={guest} />
                        <Button 
                            variant="contained" 
                            onClick={() => { 
                              setRecordRsvpForEdit(
                                {guestID: guest.guestID, 
                                  guestComments: guest.guestComments, 
                                  memberID: guest.memberID, 
                                  memberPictureUrl: guest.memberPictureUrl, 
                                  guestResponse: guest.guestResponse,
                                  memberName: guest.memberName,
                                  memberFirstName: guest.memberFirstName,
                                  memberLastName: guest.memberLastName,
                                  memberEmail: guest.memberEmail,
                                  memberPhone: guest.memberPhone,
                                }); 
                              setRsvpRecordIdx(idx);
                              setOpenRsvpPopup(true); }}>
                              RSVP
                        </Button>
                      </span>
                    )}
                    {props.member.memberID !== guest.memberID &&
                      props.event.ownerID !== props.member.memberID && (
                        <GuestCard
                          guest={guest}
                          currentTimeZone={props.currentTimeZone}
                          currentLocale={props.currentLocale}
                          venueTimeZone={props.event.venueTimeZone}
                          member={props.member}
                          // guests={records}
                          setGuests={setRecords}
                          eventOwnerID={props.event.ownerID}
                        />
                      )}
                  </AccordionDetails>
                </Accordion>
              </li>
            ))}
          </ul>
        )}
        {ownerID === userID && (
          <BottomBar showCreate="1" onCreate={handleAddGuest} />
        )}
        <Popup
          title="Guest Form"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <GuestAddForm
            recordForEdit={recordForEdit}
            addOrEdit={addOrEdit}
            eventName={props.eventName}
            eventOwner={props.eventOwner}
            venueName={props.venueName}
            eventDate={props.eventDate}
            event={props.event}
          />
        </Popup>

        <Popup
          title="RSVP Form"
          openPopup={openRsvpPopup}
          setOpenPopup={setOpenRsvpPopup}
        >
          <GuestRsvpForm
            recordForEdit={recordRsvpForEdit}
            addOrEdit={addOrEditRsvp}
            eventName={props.eventName}
            eventOwner={props.eventOwner}
            venueName={props.venueName}
            eventDate={props.eventDate}
            guestID=""
            userID={userID}
            userPictureUrl={userPictureUrl}
          />
        </Popup>
      </Box>
    </span>
  );
};

export default EventGuestsTable;

import React, { useEffect, useRef, useState } from "react";
import "./NotificationsApp.css";
import Header from "../../UI/Header";
import NotificationsHeader from "./NotificationsHeader";

//const WS_URL = "wss://" + process.env.CHAT_WEBSOCKET_ENDPOINT;
const WEBSOCKET_HOST = "794q337u0g.execute-api.us-west-2.amazonaws.com/dev";
const WEBSOCKET_URL = "wss://" + WEBSOCKET_HOST;
const WEBSOCKET_CALLBACK_URL = "https://" + WEBSOCKET_HOST + "@connections";

const handleRefresh = () => {
    console.log("handleRefresh()");
}

function NotificationsApp(props) {
  const currentMember = props.member;
  const userID = props.userID;

  console.log("Current Member: ", currentMember);
  console.log("userID: ", userID);

  return (
    <div className="flex">
      <br/>
      <Header
        showSearchField={false}
        userID={props.userID}
        userName={props.userName}
        userEmail={props.userEmail}
        userPictureUrl={props.userPictureUrl}
        member={props.member}
        title="Notifications" 
      />
{/* 
      <NotificationsHeader 
              title="Notifications" 
              refresh={handleRefresh} 
              />
 */}  
    </div>
  );
}

export default NotificationsApp;

import React from "react";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@aws-amplify/ui-react";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ThemeProvider>
    <App tab="home" />
  </ThemeProvider>
);

/*  ReactDOM.render(
  <Authenticator.Provider>
      <App />
  </Authenticator.Provider>
  ,
  document.getElementById('root')
);
 */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

import React from 'react'
// import { TextField } from '@material-ui/core';
import TextField from '@mui/material/TextField';

export default function Input(props) {

    const { name, label, value,error=null, onChange, ...other } = props;
    return (
        <TextField
            size="small"
            variant="outlined"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            InputLabelProps={{ shrink: true }}
            {...other}
            {...(error && {error:true,helperText:error})}
        />
    )
}
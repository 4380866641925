import React from 'react';
import { useState } from 'react';
import { Paper, IconButton, TextField, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import { v4 as uuid } from 'uuid';
import { Storage } from 'aws-amplify';

const AddImage = (props) => {
    const [imageData, setImageData] = useState();

    const uploadImage = async () => {
        const eventID = props.eventID;
        const ImageFilePath = props.filePath;
        console.log("imageData = ", imageData);

        //Upload the image
        const { key } = await Storage.put(`${ImageFilePath}/${uuid()}.jpeg`, imageData, { contentType: 'image/jpeg' });
        //const { key } = {};
        console.log("File Name = ", key);

        // Get the complete S3 file name and pass it back to the caller
        const s3ImageFilePath = await Storage.get(key, { expires: 300 });
        console.log("full S3 full file path = ", s3ImageFilePath);
        const arrImage = s3ImageFilePath.split("?");
        console.log("full S3 file = ", arrImage[0]);
        props.setImageFileName(key);
        props.setImageUrl(arrImage[0]);

        // Save image into the DynamoDB table if need be
/* 
        const createImageInput = {
            id: uuid(), title, description, owner, filePath: key, like: 0,
        };
        await API.graphql(graphqlOperation(createImage, { input: createImageInput }));
 */        
        // Close the popup window
        props.setOpenPopup(false);        

    };

    return (
        <div className="newImage">
            <input size="small" type="file" accept="image/*" onChange={e => setImageData(e.target.files[0])} />
            <IconButton onClick={uploadImage}>
                <PublishIcon />
            </IconButton>
        </div>
    );
};

export default AddImage;
import React from "react";
import { useState, useEffect } from "react";

import "./App.css";
import { Amplify } from 'aws-amplify';
import awsconfig from "./aws-exports";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  useAuthenticator,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import EventDetails from "./Components/events/details/EventDetails";
import HomePage from "./HomePage";
import GuestAddForm from "./Components/guests/GuestAddForm";
import MemberForm from "./Components/members/MemberForm";
import Test from "./Test";
import PublicEventPage from "./PublicEventPage";
import { getAllMembers } from "./Services/memberService";
import { getAllFavorites } from "./Services/FavoriteServices";

import { getAllGuests } from "./Services/guestService";
import ChatApp from "./Components/chat/ChatApp";
import NotificationsApp from "./Components/notifications/NotificationsApp";

Amplify.configure(awsconfig);

const App = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [member, setMember] = useState({
    memberID: "",
    memberName: "No Name",
    memberPictureUrl: "No Picture",
  });
  const [memberExists, setMemberExists] = useState(false);
  const [currentLocale, setCurrentLocale] = useState(new Intl.DateTimeFormat().resolvedOptions().locale);
  const [currentTimeZone, setCurrentTimeZone] = useState(new Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [favorites, setFavorites] = useState([]);
  const [guests, setGuests] = useState([]);
  //
  useEffect(() => {
    console.log("Fetchin member using cognito ID = ", user.username);
    const members = getAllMembers("cognitoUserID", user.username);
    members.then((response) => {
      console.log("Homepage members.then response = ", response[0]);
      if (typeof response[0] === "undefined") {
        console.log("Unable to find member for cognito user = ", user.username);
        setMemberExists(false);
      } else {
        setMemberExists(true);
        setMember(response[0]);
        if (response[0].preferredLocale !== undefined && response[0].preferredLocale !== "") {
          setCurrentLocale(response[0].preferredLocale);
        }
        if (response[0].preferredTimeZone !== undefined && response[0].preferredTimeZone !== "") {
          setCurrentTimeZone(response[0].preferredTimeZone);
        }

        // Fetch member's favorites
        const favs = getAllFavorites("memberID", response[0].memberID);
        favs.then((resp) => { setFavorites(resp) });

        // Fetch member's guest records
        // const grecs = getAllGuests("memberID", response[0].memberID);
        const grecs = getAllGuests("memberEmail", response[0].memberEmail);
        grecs.then((resp) => { setGuests(resp) });

        // TODO: fetch members notifications

        // TODO: fetch member/event(s) message count
      }
    });
  }, []);

  return (
    // <TestAddress entityID="339494904049" entityName="event"/>
//    <TestDates></TestDates>
//    <ChatApp member={member}/>
//      <MemberPickerAutoComplete />
     
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/home"
            element={
              <HomePage
                user={user}
                userID={member.memberID}
                userName={member.memberName}
                member={member}
                signOut={signOut}
                setMember={setMember}
                currentLocale={currentLocale}
                currentTimeZone={currentTimeZone}
                favorites={favorites}
                guests={guests}
              />
            }
          />
          <Route
            path="/home/:userID"
            element={
              <HomePage
                user={user}
                userID={member.memberID}
                userName={member.memberName}
                member={member}
                signOut={signOut}
                setMember={setMember}
                currentLocale={currentLocale}
                currentTimeZone={currentTimeZone}
                favorites={favorites}
                guests={guests}
              />
            }
          />
          <Route
            path="/private/:userID"
            element={
              <HomePage
                user={user}
                userID={member.memberID}
                userName={member.memberName}
                member={member}
                signOut={signOut}
                setMember={setMember}
                currentLocale={currentLocale}
                currentTimeZone={currentTimeZone}
                favorites={favorites}
                guests={guests}
              />
            }
          />
          <Route
            path="/list/:userID"
            element={
              <HomePage
                user={user}
                userID={member.memberID}
                userName={member.memberName}
                member={member}
                signOut={signOut}
                setMember={setMember}
                currentLocale={currentLocale}
                currentTimeZone={currentTimeZone}
                favorites={favorites}
                guests={guests}
              />
            }
          />
          <Route
            path="/public/:userID"
            element={
              <PublicEventPage
                user={user}
                userID={member.memberID}
                userName={member.memberName}
                userEmail={member.memberEmail}
                member={member}
                signOut={signOut}
                setMember={setMember}
                currentLocale={currentLocale}
                currentTimeZone={currentTimeZone}
              />
            }
          />
          <Route
            path="/details/:eventID/:userID/:userName"
            element={
              <EventDetails
                user={user}
                userID={member.memberID}
                userName={member.memberName}
                userEmail={member.memberEmail}
                eventCategory="PRIVATE"
                member={member}
                signOut={signOut}
                setMember={setMember}
                currentLocale={currentLocale}
                currentTimeZone={currentTimeZone}
              />
            }
          />
          <Route
            path="/details/:eventID/:userID/:userName/:openRsvp"
            element={
              <EventDetails
                user={user}
                userID={member.memberID}
                userName={member.memberName}
                userEmail={member.memberEmail}
                eventCategory="PRIVATE"
                member={member}
                signOut={signOut}
                setMember={setMember}
                currentLocale={currentLocale}
                currentTimeZone={currentTimeZone}
              />
            }
          />
          <Route
            path="/"
            element={
              <HomePage
                user={user}
                userID={member.memberID}
                userName={member.memberName}
                userEmail={member.memberEmail}
                member={member}
                signOut={signOut}
                setMember={setMember}
                currentLocale={currentLocale}
                currentTimeZone={currentTimeZone}
                favorites={favorites}
                guests={guests}
              />
            }
          />
          <Route
            user={user}
            path="/guestAdd"
            element={
              <GuestAddForm
                user={user}
                userID={member.memberID}
                userName={member.memberName}
                userEmail={member.memberEmail}
                member={member}
                signOut={signOut}
                setMember={setMember}
                currentLocale={currentLocale}
                currentTimeZone={currentTimeZone}
              />
            }
          />
          <Route
            path="/test"
            user={user}
            userID={member.memberID}
            userName={member.memberName}
            userEmail={member.memberEmail}
            element={
              <Test
                user={user}
                member={member}
                signOut={signOut}
                setMember={setMember}
                currentLocale={currentLocale}
                currentTimeZone={currentTimeZone}
                favorites={favorites}
                guests={guests}
              />
            }
          />
          <Route
            path="/profile"
            element={
              <MemberForm
                user={user}
                userID={member.memberID}
                userName={member.memberName}
                member={member}
                signOut={signOut}
                setMember={setMember}
                currentLocale={currentLocale}
                currentTimeZone={currentTimeZone}
                favorites={favorites}
                guests={guests}
              />
            }
          />
          <Route
            path="/chat"
            element={
              <ChatApp
                user={user}
                userID={member.memberID}
                userName={member.memberName}
                member={member}
                signOut={signOut}
                setMember={setMember}
                currentLocale={currentLocale}
                currentTimeZone={currentTimeZone}
                favorites={favorites}
                guests={guests}
                userPictureUrl={member.memberPictureUrl}
              />
            }
          />
          <Route
            path="/chat/:eventID"
            element={
              <ChatApp
                user={user}
                userID={member.memberID}
                userName={member.memberName}
                member={member}
                signOut={signOut}
                setMember={setMember}
                currentLocale={currentLocale}
                currentTimeZone={currentTimeZone}
                favorites={favorites}
                guests={guests}
                userPictureUrl={member.memberPictureUrl}
              />
            }
          />
          <Route
            path="/notifications"
            element={
              <NotificationsApp
                user={user}
                userID={member.memberID}
                userName={member.memberName}
                member={member}
                signOut={signOut}
                setMember={setMember}
                currentLocale={currentLocale}
                currentTimeZone={currentTimeZone}
                favorites={favorites}
                guests={guests}
                userPictureUrl={member.memberPictureUrl}
              />
            }
          />
        </Routes>
      </div>
    </Router>
 
  );
};

export default withAuthenticator(App);

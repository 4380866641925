//import { Link } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import logo from "./genzab-logo.png";
// import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Button from "@material-ui/core/Button";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import EventWhatForm from "./Components/events/details/EventWhatForm";

const Test = (props) => {
  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "50px",
    maxHeight: "50px",
  });

  return (
    <div>
      <p>User ID: {props.userID}</p>
      <p>User Name: {props.userName}</p>
      <p>User Picture Url: {props.userPictureUrl}</p>
      <p>
        <Link to="/list">List</Link>
      </p>
      <p>
        <Link to="/Details">Details</Link>
      </p>

      {/* <Tooltip title="Home"> */}
      <Button component={Link} to={"/list"}>
        <Img src={logo} />
      </Button>
      {/* </Tooltip> */}

      <Button
        component={Link}
        to={`/details/${props.eventID}/${props.userID}/${props.userName}`}
      >
        <ZoomInOutlinedIcon />
      </Button>

      <EventWhatForm
          eventID="12673399303-3=3=3i"
          eventName="Ceci est un test"
          eventDescription="Bla bla bla bla bla bla. Bla bla bla bla bla bla. Bla bla bla bla bla bla. Bla bla bla bla bla bla. Bla bla bla bla bla bla"
          eventMaxPartySize={0}
          eventType="Birthday Party"
      /> 
    </div>
  );
};

export default Test;

import * as React from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  CardActionArea,
  CardActions,
  CardHeader,
  Tooltip,
} from "@mui/material";
import "../Events.css";

import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import ShareIcon from "@mui/icons-material/Share";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import RsvpOutlinedIcon from "@mui/icons-material/RsvpOutlined";
import { Box } from "@material-ui/core";

import * as guestService from "../../../Services/guestService";
import * as memberService from "../../../Services/memberService";
import * as notificationService from "../../../Services/notificationService";

import Constants from "../../../Services/Constants";
import Popup from "../../../UI/popup";
import GuestRsvpForm from "../../guests/GuestRsvpForm";
import {
  deleteFavorite,
  insertFavorite,
} from "../../../Services/FavoriteServices";
import { useNavigate } from "react-router-dom";

export default function PrivateEventCard(props) {
  console.log("Props = ", props);
  const favorites = props.favorites !== undefined ? props.favorites : [];
  const [favorite, setFavorite] = useState(
    favorites.find((o) => o.eventID === props.eventID)
  );
  const [isFavorite, setIsFavorite] = useState(favorite !== undefined);

  const guests = props.guests !== undefined ? props.guests : {};
  const [guest, setGuest] = useState(guests.find((o) => o.eventID === props.eventID));
  const [isGuest, setIsGuest] = useState(guest !== undefined);

  let navigate = useNavigate();

  const isOwner = props.ownerID === props.member.memberID;
  const isInvited = props.userID !== props.member.memberID;
  console.log("Is Owner = ", isOwner, " - Is Invited ", isInvited);
  const locale =
    props.currentLocale !== ""
      ? props.currentLocale
      : new Intl.NumberFormat().resolvedOptions().locale;
  const currentTimeZone =
    props.currentTimeZone !== ""
      ? props.currentTimeZone
      : new Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Fetch favorites of the current event
  const separator = Constants.Separator;

  /*
   ** Manage Favorites
   ** Find out if this event is one of the vavorites
   */

  // Toggle isFavorite and delete the entry from the DB
  const UUIDGeneratorBrowser = () =>
    ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );

  function onClickFavorite() {
    console.log(
      "onClickFavorite - favorite: ",
      favorite,
      " - isfavorite: ",
      isFavorite
    );
    if (isFavorite) {
      try {
        deleteFavorite(favorite.favoriteID);
        setIsFavorite(false);
        setFavorite({});
      } catch (error) {
        console.error(error);
      }
    } else {
      const newFavoriteID = UUIDGeneratorBrowser();
      const record = {
        favoriteID: newFavoriteID,
        memberID: props.ownerID,
        eventID: props.eventID,
      };
      insertFavorite(record);
      setIsFavorite(true);
      setFavorite(record);
    }
  }

  function OnClickChat() {
    console.log("Chat on eventID: ", props.eventID)
    navigate(`/chat/${props.eventID}`);
  }

  const optionsLocalTime = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: props.venueTimeZone,
  };
  const optionsMyTime = {
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "long",
    timeZone: currentTimeZone,
  };

  const eventDateTimeLocalTime =
    props.eventStartDateTimeUTC !== undefined
      ? new Date(Math.floor(props.eventStartDateTimeUTC)).toLocaleDateString(
          locale,
          optionsLocalTime
        ) + " (local time)"
      : "";
  const eventDateTimeMyTime = "";

  // TODO Manage RSVP
  let rsvp = {
    guestID: "",
    guestResponse: "",
    guestComments: "",
    memberID: "",
    memberPictureUrl: "",
    eventID: "",
  };

  // Begin Setup RSVP popup
  const [openRsvpPopup, setOpenRsvpPopup] = useState(false);
  const [recordRsvpForEdit, setRecordRsvpForEdit] = useState(rsvp);
  const [rsvpRecord, setRsvpRecord] = useState(rsvp);

  if (guest) {
    rsvp = {
      guestID: guest.guestID,
      guestResponse: guest.guestResponse,
      guestComments: guest.guestComments,
      memberID: guest.memberID,
      memberPictureUrl: guest.memberPictureUrl,
      eventID: guest.eventID,
    };
  }

  const createNotification = (rsvp) => {  
    const textMessage = "You have a new RSVP from " + guest.memberName;
    const subject = "RSVP from " + guest.memberName;
    const summary = "You have a new RSVP from " + guest.memberName;
    const owner = memberService.getMemberByID(props.ownerID);

    const targetUrl = "/details/" + props.eventID + "/" + props.ownerID + "/" + owner.memberName;

    const notification = {
      notificationID: "",
      createDateTimeUTC: Date.now(),
      delivered: true,
      deliveredDateTimeUTC: Date.now(),
      fullMessage: textMessage,
      fullMessageType: "text",
      member: {
        country: owner.country,
        createDateTime: owner.createDateTime,
        isActive: owner.isActive,
        memberEmail: owner.memberEmail,
        memberFirstName: owner.memberFirstName,
        memberID: owner.memberID,
        memberLastName: owner.memberLastName,
        memberName: owner.memberName,
      },
      memberID: rsvp.memberID,
      subject: subject,
      summary: summary,
      targetUrl: targetUrl,
      viewed: false,
    };

    //TODO - send a notification to the owner of the event
  }

  // Add or edit a guest
  const addOrEditRsvp = (rsvp, resetRsvpForm) => {
    // Set the right response
    console.log("RSVP = ", rsvp);
    // eslint-disable-next-line default-case
    switch (rsvp.guestResponse) {
      case "accepted":
        rsvp.accepted = true;
        rsvp.declined = false;
        rsvp.notSure = false;
        break;
      case "declined":
        rsvp.accepted = false;
        rsvp.declined = true;
        rsvp.notSure = false;
        break;
      case "notsure":
        rsvp.accepted = false;
        rsvp.declined = false;
        rsvp.notSure = true;
        break;
    }
    // Send a notification to the owner of the event
    const notification = createNotification(rsvp);
    notificationService.sendNotification(notification);

    // Update the RSVP record
    guestService.updateRsvp(rsvp);
    setRsvpRecord(rsvp);
    resetRsvpForm();
    setRecordRsvpForEdit(null);
    setOpenRsvpPopup(false);
    setGuest({...guest, ...rsvp});
  };
  // End Setup RSVP

  const eventOwner = props.hasOwnProperty("eventOwner") ? props.eventOwner : "Event owner";
  const eventOwnerPictureUrl = props.hasOwnProperty("eventOwnerPictureUrl") ? props.eventOwnerPictureUrl : "";

  return (
    <Box >
      {/* <Card sx={{ maxWidth: 470 }}> */}
      <Card >
        {eventDateTimeMyTime === "" && (
          <CardHeader
            avatar={
              <Tooltip title={eventOwner}>
                <Avatar
                  src={eventOwnerPictureUrl}
                  alt={eventOwner}
                />
              </Tooltip>
            }
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
            title={props.eventName}
            subheader={eventDateTimeLocalTime}
          />
        )}
        {eventDateTimeMyTime !== "" && (
          <CardHeader
            avatar={
              <Tooltip title={eventOwner}>
                <Avatar
                  src={props.eventOwnerPictureUrl}
                  alt={props.eventOwner}
                />
              </Tooltip>
            }
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
            title={props.eventName}
            subheader={eventDateTimeLocalTime + "<br/>" + eventDateTimeMyTime}
          />
        )}

        <CardActionArea
          component={Link}
          to={`/details/${props.eventID}/${props.userID}/${props.userName}`}
        >
          <CardMedia
            component="img"
            height="140"
            image={props.venueImageUrl}
            alt={props.venueName}
          />
          <CardContent>
            {props.venueName === "" && (
              <Typography variant="body2" color="text.secondary">
                {props.venueCity}
                {", "} {props.venueCountry} <br />
              </Typography>
            )}
            {props.venueName !== "" && (
              <Typography variant="body2" color="text.secondary">
                {props.venueName} <br />
                {props.venueCity}
                {", "} {props.venueCountry} <br />
              </Typography>
            )}
          </CardContent>
        </CardActionArea>

        <CardActions disableSpacing>
          {isFavorite && (
            <Tooltip title="Remove from Favorite">
              <IconButton
                onClick={(e) => {
                  onClickFavorite();
                }}
              >
                <FavoriteIcon />
              </IconButton>
            </Tooltip>
          )}
          {!isFavorite && (
            <Tooltip title="Move to Favorite">
              <IconButton
                onClick={(e) => {
                  onClickFavorite();
                }}
              >
                <FavoriteBorderIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Share or invite people">
            <IconButton aria-label="Share">
              <ShareIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Chat on this event">
            <IconButton aria-label="Chat" onClick={OnClickChat}>
              <ChatOutlinedIcon />
            </IconButton>
          </Tooltip>
          {/* Show RSVP if the logged user is not the owner of this event*/}
          {isGuest && (
            <Tooltip title="Accept or Decline the invitation">
              {/* <Badge badgeContent={4} color="error"> */}
              <IconButton
                color="primary"
                aria-label="RSVP"
                component="span"
                onClick={() => {
                  setOpenRsvpPopup(true);
                  setRecordRsvpForEdit(guest);
                  setRsvpRecord(guest);
                }}
              >
                <RsvpOutlinedIcon />
              </IconButton>
              {/* </Badge> */}
            </Tooltip>
          )}
        </CardActions>
      </Card>

      <Popup
        title="RSVP Form"
        openPopup={openRsvpPopup}
        setOpenPopup={setOpenRsvpPopup}
      >
        <GuestRsvpForm
          recordForEdit={recordRsvpForEdit}
          addOrEdit={addOrEditRsvp}
          eventName={props.eventName}
          eventOwner={props.eventOwner}
          venueName={props.venueName}
          eventDate={props.eventDate}
          guestID=""
          userID={props.userID}
          userPictureUrl={props.userPictureUrl}
          userEmail={props.userEmail}
        />
      </Popup>
    </Box>
  );
}

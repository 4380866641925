import * as React from "react";
import { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Container, ListItemButton } from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { styled } from "@mui/material/styles";
import Popup from "../../../UI/popup";
import EventDateTimesForm from "./EventDateTimesForm";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const EventDetailsDateTime = (props) => {
  const [address, setAddress] = useState({});
  const [openAddressPopup, setOpenAddressPopup] = useState(false);
  const [venue, setVenue] = useState({});
  const [openVenuePopup, setOpenVenuePopup] = useState(false);

  const [eventDateTime, setEventDateTime] = useState({
    eventID: props.event.eventID,
    eventStartDateTimeUTC: props.event.eventStartDateTimeUTC,
    eventEndDateTimeUTC: props.event.eventEndDateTimeUTC,
    eventDate: props.event.eventDate,
    eventStartTime: props.event.eventStartTime,
    eventEndTime: props.event.eventEndTime,
  });
  const [openDateTimePopup, setOpenDateTimePopup] = useState(false);

  const currentEventDateTime = {
    eventID: props.event.eventID,
    eventStartDateTimeUTC: props.event.eventStartDateTimeUTC,
    eventEndDateTimeUTC: props.event.eventEndDateTimeUTC,
    eventDate: props.event.eventDate,
    eventStartTime: props.event.eventStartTime,
    eventEndTime: props.event.eventEndTime,
  };

  console.log("Current Date/Time = ", currentEventDateTime);
  console.log("Date/Time = ", eventDateTime);

  const eventDate = props.event.eventDate;
  const isCoHost = props.isCoHost;
  const timeZone = props.event.venueTimeZone === undefined ? props.currentTimeZone : props.event.venueTimeZone;
  const locale = props.currentLocale;
/* 
  if (timeZone !== undefined && timeZone !== "") {
    console.log("setting TZ to: ", timeZone);
    moment.tz.setDefault(timeZone);
  }
 */
  const dateOptions = { 
    weekday: 'long', 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric',
    timeZone: timeZone,
   };

  const timeOptions = { 
    timeStyle: 'short',
    timeZone: timeZone,
   };

  const displayEventDate = eventDateTime.eventStartDateTimeUTC !== undefined 
  ? new Date(Math.floor(eventDateTime.eventStartDateTimeUTC)).toLocaleDateString(locale, dateOptions)
  : currentEventDateTime.eventStartDateTimeUTC !== undefined 
    ? new Date(Math.floor(currentEventDateTime.eventStartDateTimeUTC)).toLocaleDateString(locale, dateOptions): "";

  const displayEventStartTime = eventDateTime.eventStartDateTimeUTC !== undefined 
  ? new Date(Math.floor(eventDateTime.eventStartDateTimeUTC)).toLocaleTimeString(locale, timeOptions)
  : currentEventDateTime.eventStartDateTimeUTC !== undefined
    ? new Date(Math.floor(currentEventDateTime.eventStartDateTimeUTC)).toLocaleTimeString(locale, timeOptions) : "";

    const displayEventEndTime = eventDateTime.eventEndDateTimeUTC !== undefined 
  ? new Date(Math.floor(eventDateTime.eventEndDateTimeUTC)).toLocaleTimeString(locale, timeOptions)
  : currentEventDateTime.eventEndDateTimeUTC !== undefined
    ? new Date(Math.floor(currentEventDateTime.eventEndDateTimeUTC)).toLocaleTimeString(locale, timeOptions) : "";

  return (
    <Container disableGutters>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        <ListItem alignItems="flex-start">
          <ListItemIcon>TZ</ListItemIcon>
          <ListItemText>{timeZone}</ListItemText>
        </ListItem>

        {!isCoHost && (
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <CalendarMonthOutlinedIcon alt="Date" />
            </ListItemIcon>
            <ListItemText>{displayEventDate}</ListItemText>
          </ListItem>
        )}
        {!isCoHost && (
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <AccessTimeOutlinedIcon alt="Time" />
            </ListItemIcon>
            <ListItemText>
              {displayEventStartTime}
              <br />
              {displayEventEndTime}
            </ListItemText>
          </ListItem>
        )}

        {isCoHost && (
          <div>
            <ListItemButton
              alignItems="flex-start"
              key="EventDetailsDateTime.03"
              onClick={() => {
                console.log("currentEventDateTime: ",currentEventDateTime);
                setOpenDateTimePopup(true);
              }}
            >
              <ListItemIcon>
                <CalendarMonthOutlinedIcon alt="Date" />
              </ListItemIcon>
                <ListItemText>{displayEventDate}</ListItemText>
            </ListItemButton>

            <ListItemButton
              alignItems="flex-start"
              key="EventDetailsDateTime.04"
              onClick={() => {
                setOpenDateTimePopup(true);
              }}
            >
              <ListItemIcon>
                <AccessTimeOutlinedIcon alt="Date" />
              </ListItemIcon>
                <ListItemText>
                  {displayEventStartTime}
                  <br />
                  {displayEventEndTime}
                </ListItemText>
            </ListItemButton>
          </div>
        )}
      </List>

      <Popup
        title="Event Date/Time"
        openPopup={openDateTimePopup}
        setOpenPopup={setOpenDateTimePopup}
      >
        <EventDateTimesForm
          eventDateTime={currentEventDateTime}
          venueTimeZone={props.event.venueTimeZone}
          setDateTime={setEventDateTime}
          setOpenPopup={setOpenDateTimePopup}
          currentLocale={locale}
        />
      </Popup>
    </Container>
  );
};

export default EventDetailsDateTime;
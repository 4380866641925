import * as React from "react";
import AppBar from "@mui/material/AppBar";
import "./../App.css";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import logo from "../genzab-logo.png";
import getDefaultMemberPicture from "../Components/members/getDefaultMemberPicture";
// import { SearchField } from "@aws-amplify/ui-react";
import SearchField from "react-search-field";

const pages = ["Home", "Private Events", "Public Events"];
const settings = ["Profile", "Network", "Dashboard", "Settings", "Logout"];
const myUserPictureUrl =
  "https://media-exp1.licdn.com/dms/image/C4E03AQHr4ldOTVuZNQ/profile-displayphoto-shrink_200_200/0/1516960147950?e=1652918400&v=beta&t=e-s2s0zez09cLvYkM6rFWKnxAJDNb1pjl-q68OrRPdE";

const MY_PRIVATE_EVENTS = "Private Events";
const PUBLIC_EVENTS = "Public Events";
const HOME = "Home";

const LOGOUT = "Logout";
const PROFILE = "Profile";
const NETWORK = "Network";
const SETTINGS = "Settings";
const DASHBOARD = "Dashboard";

const Header = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  // Search text
  const [queryString, setQueryString] = React.useState("");

  let { userID, userPictureUrl, userName } = props;
  let navigate = useNavigate();

  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "50px",
    maxHeight: "50px",
  });

  const handleChange = (targetString) => {
    // console.log("handleChange target: ", targetString);

    switch (targetString.trim()) {
      case HOME:
        console.log("Home is selected");
        navigate(`/home`);
        break;
      case MY_PRIVATE_EVENTS:
        console.log("myPrivateEvents is selected");
        navigate(`/private/${userID}`);
        break;
      case PUBLIC_EVENTS:
        console.log("PublicEvents is selected");
        navigate(`/public/${userID}`);
        break;
    }
  };

  const handleOpenNavMenu = (event) => {
    console.log("handleOpenNavMenu: ", event.currentTarget.textContent);
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    console.log("handleOpenUserMenu: ", event.currentTarget.textContent);
    setAnchorElUser(event.currentTarget);
  };
  async function handleLogout() {
    await Auth.signOut();
  }
  function handleProfile() {
    navigate(`/profile`);
  }
  function handleOpenChat() {
    navigate(`/chat`);
  }
  function handleOpenNotifications() {
    navigate(`/notifications`);
  }

  const handleCloseNavMenu = (event) => {
    console.log("handleCloseNavMenu: ", event.currentTarget.textContent);
    setAnchorElNav(null);
    handleChange(event.currentTarget.textContent);
  };

  const handleCloseUserMenu = (event) => {
    console.log("handleCloseUserMenu: ", event.currentTarget.textContent);

    switch (event.currentTarget.textContent) {
      case LOGOUT:
        handleLogout();
        break;
      case PROFILE:
        handleProfile();
        break;
    }

    // if ( event.currentTarget.textContent == "Logout") { handleLogout() }

    setAnchorElUser(null);
    handleChange(event.currentTarget.textContent);
  };

  if (userPictureUrl == null) {
    const member = getDefaultMemberPicture(userID);
    userID = member.memberID;
    userPictureUrl = member.memberPictureUrl;
    userName = member.hasOwnProperty("memberName") ? member.memberName: "TODO: Find username";
  }

  function onSearchChange(value, event) {
    setQueryString(value);
    props.setQuery(value);
  }

  function onSearchClick(value) {
    setQueryString(value);
  }

  const disableSearch = !props.showSearchField;

  return (
    <AppBar position="fixed" color="primary" 
            sx={{ top: 0, alignItems: "center", justifyContent: 'space-between', gap: 0.5, bottom: "auto" }}>
      <Container maxWidth="xl" disableGutters>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, align: "center", 
                      gap: 0.5, alignItems: "center", 
                      alignContent: 'space-between' , 
                      display: { xs: "flex", md: "none" } }}>
            <Tooltip title="Main menu">
              {/* <Button component={Link} to={`/home`}> */}
              <Button onClick={handleOpenNavMenu} size="small" >
                <Img src={logo} />
              </Button>
            </Tooltip>

            <Menu
              id="menu-appbar2"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>

                { !disableSearch && (
                  <div className="header">
                  <SearchField 
                    disabled={disableSearch}
                    label="Search"
                    size="small"
                    placeholder="Search ..."
                    onChange={onSearchChange}
                    onSearchClick={onSearchClick}
              ></SearchField>
                  </div>
                )}
                {disableSearch && (
                  <div className="header">
                    {props.title}
                  </div>
                )}

            <Tooltip title="Notifications center">
              <IconButton
                size="small"
                aria-label="show new notifications"
                color="inherit"
                onClick={handleOpenNotifications} 
              >
                <Badge badgeContent={17} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip>

            <Tooltip title={userName}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Current User" src={userPictureUrl} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar3"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;

import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";
import { Tooltip } from "@material-ui/core";
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: "0 auto",
});

export default function BottomBar(props) {
  const {
    showCreate,
    showFilter,
    showSort,
    showMyTickets,
    showChat,
    onCreate,
    onFilter,
    onSort,
    onShowMyTickets,
    onShowChat,
  } = props;

  return (
    <React.Fragment>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" color="primary" sx={{ top: "auto", bottom: 0 }}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 0,
            m: 0,
            // bgcolor: 'background.paper',
            borderRadius: 1,
          }}
        >
          {/* <StyledFab color="secondary" aria-label="add">
            <AddIcon />
          </StyledFab> */}
          {/* <Box sx={{ flexGrow: 1 }} />  */}
          {showCreate === "1" && (
            <Tooltip title="Create New">
              <IconButton color="inherit" onClick={onCreate}>
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}

          {showFilter === "1" && (
            <Tooltip title="Filter">
              <IconButton color="inherit" onClick={onFilter}>
                <FilterAltOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}

          {showSort === "1" && (
            <Tooltip title="Sort">
              <IconButton color="inherit" onClick={onSort}>
                <SortOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          {showMyTickets === "1" && (
            <Tooltip title="My Invites">
              <IconButton color="inherit" onClick={onShowMyTickets}>
                <StyleOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          {showChat === "1" && (
            <Tooltip title="Available Chats">
              <IconButton color="inherit" onClick={onShowChat}>
                <ChatBubbleOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          {/* 
          <Tooltip title="More Actions">
          <IconButton color="inherit">
            <MoreIcon />
          </IconButton>
          </Tooltip>
 */}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

import axios from "axios";
import Constants from "./Constants";

const favoriteAPIUrl = Constants.APIUrlString.Favorites;
const separator = Constants.Separator;

async function isFavoriteAsync(memberID, eventID) {
  const url =
    favoriteAPIUrl +
    "/favorites?queryKey=memberID" +
    separator +
    "eventID" +
    "&queryValue=" +
    memberID +
    separator +
    eventID;
  const result = await axios.get(url);
  if (result.data === undefined) {
    console.error("Error fetching data using URL: ", url);
    console.error("Result = ", result);
    return false;
  }
  console.log("result.data = ", result.data);

  return result.data.length > 0;
}

export function isFavorite(memberID, eventID) {
  return isFavoriteAsync(memberID, eventID);
}

/*
Get all members asynchronously
*/
async function getAllFavoritesAsync(queryKey, queryValue) {
  const RESTUrl =
    favoriteAPIUrl +
    "/favorites" +
    "?queryKey=" +
    queryKey +
    "&queryValue=" +
    queryValue;
  console.log("getAllFavorites URL: ", RESTUrl);

  const res = await axios.get(RESTUrl);
  console.log("getAllFavoritesAsync members[0]: ", res.data[0]);
  return res.data;
}

export function getAllFavorites(queryKey, queryValue) {
  return getAllFavoritesAsync(queryKey, queryValue);
}

/*
 ** Delete a favorite record
 */
export function deleteFavorite(favoriteID) {
  const url = favoriteAPIUrl + "/favorite?favoriteID=" + favoriteID;
  axios({
    method: "DELETE",
    url: url,
    data: { favoriteID: favoriteID },
    validateStatus: () => true,
  })
    .then((response) => {
      console.log("Deleted favorite: ", response);
      return response.data;
    })
    .catch((error) => {
      console.error("Unable to delete favorite: ", error);
      console.error(" - Tried URL: ", url);
      console.error(" - Failed favorite: ", favoriteID);
    });
}

/*
 ** Insert Favorite
 */
export function insertFavorite(favorite) {
  const url = favoriteAPIUrl + "/favorite";
  axios({
    method: "POST",
    url: url,
    data: favorite,
    validateStatus: () => true,
  })
    .then((response) => {
      console.log("Inserted favorite: ", response);
      return response.data;
    })
    .catch((error) => {
      console.error("Unable to insert favorite: ", error);
      console.error(" - Tried URL: ", url);
      console.error(" - Failed favorite: ", favorite);
    });
}
/*
 ** Function: buildResponse
 ** Purpose: format  error code/message
 */
function buildResponse(statusCode, body) {
  return {
    statusCode: statusCode,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
}

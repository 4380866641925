import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import ErrorPage from "../../../UI/ErrorPage";
import LoadingPage from "../../../UI/LoadingPage";
import NoDataFound from "../../../UI/NoDataFound";
import EventGuestsTable from "./EventGuestsTable";

const EventGuest = (props) => {
  const url =
    "https://dnmm09r5o0.execute-api.us-west-2.amazonaws.com/guests?queryKey=eventID&queryValue=";
  const { eventID } = useParams();
  const { data: initialGuestList, error, isLoading, isEmpty } = useFetch(url + eventID);

  var guests = [];
  initialGuestList.forEach((guest) => {
    if (!guest.hasOwnProperty("guestResponse")) {
      guest.guestResponse = "notsure";
    } else if (guest.guestResponse === null) {
      guest.guestResponse = "notsure";
    }
    guests.push(guest);
  });

  console.log("props = ", props);

  return (
    <span className="guests-list">
      {isLoading && <LoadingPage message="Loading Data..." />}
      {error && <ErrorPage message={error} />}
      {!isLoading && isEmpty && <NoDataFound message="No Guest Invited Yet" />}
      {!isLoading && <EventGuestsTable {...props} isEmpty={isEmpty} rows={guests} />}
    </span>
  );
};

export default EventGuest;

import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { useNavigate } from "react-router";
import { CenterFocusStrong } from "@material-ui/icons";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const NotificationsHeader = (props) => {

  const navigate = useNavigate();
  function handleBackButtonClick(e) {
    // console.log("handleBackButtonClick - event = ", e);
    navigate(-1);
  }

  const handleRefreshClick = () => {
    props.refresh();
  }

  return (
    <Paper
      sx={{
        p: 2,
        margin: "auto",
        maxWidth: 470,
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      }}
    >
      <Grid container spacing={2} direction="row" alignContent="center">
        <Grid item xs={2}>
          <IconButton onClick={handleBackButtonClick}>
            <ArrowBackIosOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom variant="h6" component="div">
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={handleRefreshClick}>
            <RefreshOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default NotificationsHeader;

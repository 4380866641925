import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import scriptLoader from "react-async-script-loader";
import { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';
import axios from "axios";

function AddressAutoComplete (props) {
//   const [address, setAddress] = React.useState(props.address);
//  console.log("props = ", props);

async function getPlaceTimeZone (lat, lon) {
  const googleAPIKey = "AIzaSyAxfabpgNruBh_Hq0tOsYAlzfH6AXJfV6c";
  const url = "https://maps.googleapis.com/maps/api/timezone/json" +
    "?location=" + lat + "%2C" + lon +
    "&timestamp=1331161200" +
    "&key=" + googleAPIKey;
  try {
    const result = await axios.get ( url );
    // console.log("URL = ", url);
    console.log("Google TimeZone = ", result.data);
    if (result.data.status === "ZERO_RESULTS") {
      console.log("No result from URL: ", url);
      return "";
    } else {
      return result.data.timeZoneId;
    }
    
  } catch(e) {
    console.error("getPlaceTimeZone url: ", url);
    console.error("getPlaceTimeZone ERROR: ", e);
  }
}

  const handleChange = (value) => {
    props.setFullString(value);
  }

  const handleSelect = async (value, placeId) => {
    console.log("Address = ", value);

    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    const [place] = await geocodeByPlaceId(placeId);
    console.log(" Place = ", place);
    console.log(" geocodeByAddress() result = ", results);
    console.log(" Latitude / Longitude = ", latLng);
    console.log(" address_components ", place.address_components);

    const { long_name: streetNumber = '' } =
      place.address_components.find(c => c.types.includes('street_number')) || {};
    const { long_name: streetName = '' } =
      place.address_components.find(c => c.types.includes('route')) || {};
    //console.log(" address = ", streetNumber + " " + streetName);
    const { long_name: city = '' } =
      place.address_components.find(c => c.types.includes('locality')) || {};
    //console.log(" city = ", city);
    const { long_name: postalCode = '' } =
      place.address_components.find(c => c.types.includes('postal_code')) || {};
      const { long_name: postalCodeSuffix = '' } =
    place.address_components.find(c => c.types.includes('postal_code_suffix')) || {};
    const fullPostalCode = postalCodeSuffix === "" ? postalCode : postalCode+"-"+postalCodeSuffix;
    console.log(" postalCodeSuffix = ", postalCodeSuffix);
    console.log(" fullPostalCode = ", fullPostalCode);

    const { long_name: metroArea = '' } =
      place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};
    //console.log(" province = ", metroArea);
    const { long_name: province = '' } =
      place.address_components.find(c => c.types.includes('administrative_area_level_1')) || {};
    //console.log(" province = ", province);
    const { long_name: country = '' } =
      place.address_components.find(c => c.types.includes('country')) || {};
    const { short_name: countryShort = '' } =
      place.address_components.find(c => c.types.includes('country')) || {};
    //console.log(" country short = ", countryShort);

    const timeZone = await getPlaceTimeZone(latLng.lat, latLng.lng);
    console.log("Time zone at this location: ", timeZone);

    props.setFullString(place.formatted_address);
    const addr = {
      addressString1: streetNumber + " " + streetName,
      city: city,
      postalCode: fullPostalCode,
      metroArea: metroArea,
      province: province,
      country: country,
      countryShort: countryShort,
      coordinates: latLng,
      fullString: streetNumber + " " + streetName + ", " + city + ", " + country,
      formatted_address: place.formatted_address,
      timeZone: timeZone,
    };
    props.setAddress(addr);

  }

    return (
      <div>
        <PlacesAutocomplete
          value={props.address}
          onChange={handleChange}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: "Enter Address...",
                })}
              />
              <div>
                {loading && <div>Loading...</div>} 
                {suggestions.map((suggestion) => {
                  const style = suggestion.active
                    ? { backgroundColor: "#a83232", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                    // console.log("Suggestion = ", suggestion);

                  return (
                    <div {...getSuggestionItemProps(suggestion, { style })}>
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
}


// Google API key AIzaSyAxfabpgNruBh_Hq0tOsYAlzfH6AXJfV6c
export default scriptLoader([
  `https://maps.googleapis.com/maps/api/js?key=AIzaSyAxfabpgNruBh_Hq0tOsYAlzfH6AXJfV6c&libraries=places&loading=async`,
]) (AddressAutoComplete);

import React from 'react'
import TextField from '@mui/material/TextField';

export default function InputMulti(props) {

    const { name, label, value,error=null, onChange, maxRows, ...other } = props;
    return (
        <TextField size="small"
            variant="outlined"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            multiline
            maxRows={maxRows}
            {...other}
            {...(error && {error:true,helperText:error})}
        />
    )
}
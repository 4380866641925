const ImageConstants = {
    imageType: {
        unavailableImage: "Unavailable Image",
        happyBirthDayImage: "Happy Birthday Image",
        defaultPartyImage: "Default Image",
    },

    imageFilePath: {
        unavailableImage: "public/common/default/no-image-available-1.jpeg",
        HappyBirthImage: "public/common/default/default-happy-birth-day.jpeg",
        defaultImage: "public/common/default/default-party.jpeg"
    },

    bucketURL: "https://genzab-content183420-dev.s3.us-west-2.amazonaws.com"
}
  
  export default ImageConstants;



import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { red } from "@mui/material/colors";

export default function ChatEventCard(props) {
  console.log("Props: ", props);
  const venueTimeZone =
    props.venueTimeZone !== undefined
      ? props.venueTimeZone
      : "America/Vancouver";

  const optionsLocalTime = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: venueTimeZone,
  };

  const handleOnClick = () => {
    console.log("on Click - event: ", props.eventName);
    props.setTargetEvent(props.event);
    props.onEventClick();
  };

  const locale = Intl.NumberFormat().resolvedOptions().locale;
  const eventDateTimeLocalTime =
    props.eventStartDateTimeUTC !== undefined
      ? new Date(Math.floor(props.eventStartDateTimeUTC)).toLocaleDateString(
          locale,
          optionsLocalTime
        ) + " (local time)"
      : "";

  return (
    <Card className="chat-event-card">
      <CardActionArea onClick={handleOnClick}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.eventName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.venueAddress}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

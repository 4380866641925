import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../UI/controls/Controls";
import { useForm, Form } from "../../UI/useForm";

import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
} from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import MemberPickerAutoComplete from "../../UI/controls/MemberPickerAutoComplete";

const preferredCommunicationItems = [
  { id: "email", title: "E-mail" },
  { id: "SMS", title: "SMS" },
];

export default function GuestAddForm(props) {
  const useStyles = makeStyles((theme) => ({
    pageContent: {
      margin: theme.spacing(5),
      padding: theme.spacing(0),
      width: "100%",
    },
    searchInput: {
      width: "75%",
    },
    newButton: {
      position: "absolute",
      right: "10px",
    },
  }));

  const classes = useStyles();

  const { addOrEdit, recordForEdit } = props;
  const dateNow = Date.now();
  const deadLineDateTime = 
    props.event.eventDateTimeUTC - (15*24*3600*1000) < dateNow
    ? props.event.eventDateTimeUTC 
    : props.event.eventDateTimeUTC - (15*24*3600*1000);

  const initialFValues = {
    guestID: "",
    memberLastName: "",
    memberEmail: "",
    memberPhone: "",
    memberFirstName: "",
    preferredCommunication: "email",
    deadLineDateTime: deadLineDateTime,
    memberID: "",
    memberName: "",
    memberPictureUrl: "",
  };  

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("memberLastName" in fieldValues)
      temp.memberLastName = fieldValues.memberLastName
        ? ""
        : "Last name is required.";
    if ("memberFirstName" in fieldValues)
      temp.memberFirstName = fieldValues.memberFirstName
        ? ""
        : "First name is required.";
    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  const handleNewValues = (newValues) => {
    console.log("New Values: ", newValues);
    setValues({...values, 
      memberName: newValues.memberName,
      memberFirstName: newValues.memberFirstName,
      memberLastName: newValues.memberLastName,
      memberPictureUrl: newValues.memberPictureUrl,
      memberPhone: newValues.memberPhone,
      memberEmail: newValues.memberEmail,
      memberID: newValues.memberID})
  }

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  return (
    <Paper className={classes.pageContent}>

      <MemberPickerAutoComplete setValues={handleNewValues} />
      <br/>

      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Controls.Input
              name="memberFirstName"
              label="First Name"
              value={values.memberFirstName}
              onChange={handleInputChange}
              error={errors.memberFirstName}
              disabled={values.memberID !== "" ? true : false}
            />
            <Controls.Input
              name="memberLastName"
              label="Last Name"
              value={values.memberLastName}
              onChange={handleInputChange}
              error={errors.memberLastName}
              disabled={values.memberID !== "" ? true : false}
            />
            <Controls.Input
              name="memberEmail"
              label="Email"
              value={values.memberEmail}
              onChange={handleInputChange}
              error={errors.memberEmail}
              disabled={values.memberID !== "" ? true : false}
            />
            <Controls.Input
              label="Mobile"
              name="memberPhone"
              value={values.memberPhone}
              onChange={handleInputChange}
              error={errors.memberPhone}
              disabled={values.memberID !== "" ? true : false}
            />

            <Controls.RadioGroup
              name="preferredCommunication"
              label="Preferred Communication Mode"
              value={values.preferredCommunication}
              onChange={handleInputChange}
              items={preferredCommunicationItems}
            />
            <span>
              <Controls.Button
                text="Reset"
                color="default"
                onClick={resetForm}
              />
              <Controls.Button type="submit" text="Submit" />
            </span>
          </Grid>
        </Grid>
      </Form>
   
    </Paper>
  );
}

import axios from "axios";
import Constants from "./Constants";

const privEventRESTUrl = Constants.APIUrlString.PrivEvents;
const pubEventRESTUrl = Constants.APIUrlString.PubEvents;

export function insertPubEvent(data) {
  console.log("insertPubService: ", data);
}

export function insertPrivEvent(data) {
  const url = privEventRESTUrl + "/event";
  console.log("insertPrivService: ", data);

  var event = data;
  event.eventCreationDateTime = Date.now();
  event.createdDateTimeUTC = Date.now();
  event.updatedDateTimeUTC = Date.now();

  //   axios.post(url, data, headers ).
  axios
    .post(url, event)
    .then((res) => {
      console.log("eventService.insertPrivEvent return: ", res.status);
      console.log("eventService.insertPrivEvent res.data: ", res);
      return buildResponse(res.status, res.data);
    })
    .catch((err) => {
      console.error(err);
      const ErrorBody = {
        Operation: "insertPrivService",
        Message: err,
        Item: event,
      };
      return buildResponse(500, ErrorBody);
    });
}

// Update What section of the event
export function updateEventWhat(
  eventID,
  eventType,
  eventName,
  eventDescription,
  venueImageUrl
) {
  const url = privEventRESTUrl + "/event";
  const separator = "~";
  console.log("eventService.updateEventWhat: ", eventID);
  const updatedDateTimeUTC = Date.now();

  updateAttributes(
    eventID,
    "eventType" +
      separator + "eventName" +
      separator + "eventDescription" +
      separator + "venueImageUrl" +
      separator + "updatedDateTimeUTC",
    eventType +
      separator + eventName +
      separator + eventDescription +
      separator + venueImageUrl +
      separator + updatedDateTimeUTC
  );
}

export function updateEventAddress(eventID, address, venueName = "DO NOT UPDATE") {
  const url = privEventRESTUrl + "/event";
  const separator = "~";
  const updatedDateTimeUTC = Date.now();

  console.log("eventService.updateEventAddress: ", eventID, address, venueName);
  updateAttributes(
    eventID,
    "venueAddressString1" 
    + separator + "venueAddressString2" 
    + separator + "venueCity" 
    + separator + "venuePostalCode" 
    + separator + "venueProvince" 
    + separator + "venueMetroArea" 
    + separator + "venueCountry" 
    + separator + "venueCountryShort" 
    + separator + "venueAddress"
    + (venueName === "DO NOT UPDATE" ? "" : separator + "venueName")
    + separator + "venueTimeZone"
    + separator + "updatedDateTimeUTC"
    ,
    address.addressString1 
    + separator +
      (typeof address.addressString2 === "undefined"
        ? ""
        : address.addressString2) 
    + separator + address.city 
    + separator + address.postalCode 
    + separator + address.province 
    + separator + address.metroArea 
    + separator + address.country 
    + separator + address.countryShort 
    + separator + address.fullString 
    + (venueName === "DO NOT UPDATE" ? "" : separator + venueName)
    + separator + address.timeZone
    + separator + updatedDateTimeUTC
    );

  // Update the Coordinates
  updateAttribute(eventID, "venueCoordinates", JSON.stringify(address.coordinates), true);

}

export function updateEventDates(
    eventID
    , eventStartDateTimeUTC, eventEndDateTimeUTC, eventTimeZone
    , eventDate, eventStartTime, eventEndTime) {
  const url = privEventRESTUrl + "/event";
  const separator = "~";
  const updatedDateTimeUTC = Date.now();

  console.log("eventService.updateEventDates: "
    , eventID, eventStartDateTimeUTC, eventEndDateTimeUTC, eventTimeZone
    , eventDate, eventStartTime, eventEndTime);
  updateAttributes(
    eventID,
    "eventStartDateTimeUTC" 
    + separator + "eventDateTimeUTC" 
    + separator + "eventEndDateTimeUTC" 
    + separator + "eventTimeZone"
    + separator + "eventDate"
    + separator + "eventStartTime"
    + separator + "eventEndTime"
    + separator + "updatedDateTimeUTC"
    ,
    eventStartDateTimeUTC 
    + separator + eventStartDateTimeUTC 
    + separator + eventEndDateTimeUTC 
    + separator + eventTimeZone 
    + separator + eventDate
    + separator + eventStartTime
    + separator + eventEndTime
    + separator + updatedDateTimeUTC
  );

}

// Update a single event attribute
function updateAttribute(eventID, updateKey, updateValue, isJSON=false) {
  var data = {
    eventID: eventID,
    updateKey: updateKey,
    updateValue: updateValue,
    isJSON: isJSON
  };
  const url = privEventRESTUrl + "/event";
  console.log("eventService.updateAttribute() - URL: ", url);
  console.log("eventService.updateAttribute() - Data: ", data);
  // Call the REST API
  axios
    .patch(url, data)
    .then((res) => console.log("Ok - response: ", res))
    .catch((err) => console.error("Failed: ", err));
}

// Update multiple event attributes at once
export function updateAttributes(eventID, updateKey, updateValue) {
  var data = {
    eventID: eventID,
    updateKey: updateKey,
    updateValue: updateValue,
  };
  const url = privEventRESTUrl + "/event";
  console.log("eventService.updateAttributes() - URL: ", url);
  console.log("eventService.updateAttributes() - Data: ", data);
  // Call the REST API
  axios
    .patch(url, data)
    .then((res) => {
      console.log("Ok - response: ", res);
      return buildResponse(res.status, res.data);
    })
    .catch((err) => console.error("Failed: ", err));
}

/*
 ** Function: buildResponse
 ** Purpose: format  error code/message
 */
function buildResponse(statusCode, body) {
  return {
    statusCode: statusCode,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
}

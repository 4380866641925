import * as React from "react";
import { useParams } from "react-router";

import Header from "./UI/Header";
import PublicEventsList from "./Components/events/list/PublicEventsList";
import { Box, Button, Grid } from "@mui/material";
import getDefaultMemberPicture from "./Components/members/getDefaultMemberPicture";

const PublicEventPage = (props) => {
  let { userID } = useParams();
  if ( userID == null ) { userID = props.userID }

  const member = props.member;
  
  return (
    <Box>
      <Header userID={member.memberID} userName={member.memberName} userPictureUrl={member.memberPictureUrl} {...props}/>
      <Box><Button></Button></Box>
      <PublicEventsList userID={member.memberID} userName={member.memberName} userPictureUrl={member.memberPictureUrl} metroArea="TORONTO" {...props} />
   </Box>
  );
};

export default PublicEventPage;

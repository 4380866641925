import * as React from "react";
import { useState } from "react";
import useFetch from "../../hooks/useFetch";
import NoDataFound from "../../UI/NoDataFound";
import ErrorPage from "../../UI/ErrorPage";
import LoadingPage from "../../UI/LoadingPage";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { Box, CardActionArea } from "@mui/material";

import "./GuestCard.css";

const GUEST_RESTAPI_HOST = "dnmm09r5o0.execute-api.us-west-2.amazonaws.com";
const separator = "~";

const GuestRsvpPage = (props) => {
  console.log("Props: ", props);

  /* 
  const url = "https://" +  GUEST_RESTAPI_HOST +
    "/guests?queryKey=" + "eventID" + separator + "memberID" + "&queryValue=" +
    event.eventID + separator + member.memberID;
  console.log("Fetching guest using URL: ", url);
  const { data: guests, error, isLoading, isEmpty } = useFetch(url);
  const guest = guests[0];
 */
  const isLoading = false;
  const isEmpty = false;
  const error = null;

  const guest = props.guest;
  console.log("Guest: ", guest);

  const rsvpStatus =
    guest.guestResponse === undefined
      ? "No response yet"
      : " Response: " +
        (guest.guestResponse === "accepted" ? "Accepted" : (guest.guestResponse === "declined" ? "Declined" : "Not Sure")) +
        (guest.guestComments === undefined
          ? " "
          : " - Comments: " + guest.guestComments);

  return (
    <span>
      <Box alignItems="left" sx={{ maxWidth: 465 }}>
        {isLoading && <LoadingPage message="Loading Data..." />}
        {error && <ErrorPage message={error} />}
        {!isLoading && isEmpty && (
          <NoDataFound message="No Guest Invited Yet" />
        )}
        {!isLoading && (
          <Card sx={{ width: "100%", borderRadius: "10pt" }} variant="outlined">
            <CardActionArea>
              <CardHeader
                size="small"
                sx={{ paddingBottom: "0", paddingTop: "1pt" }}
                avatar={
                  <Avatar
                    src={guest.memberPictureUrl}
                    alt={guest.memberName}
                    aria-label={guest.memberName}
                  />
                }
                title={guest.memberName + " - " + guest.memberEmail}
                subheader={rsvpStatus}
              />
            </CardActionArea>
          </Card>
        )}
      </Box>
    </span>
  );
};

export default GuestRsvpPage;

import { Storage } from 'aws-amplify';
import ImageConstants from './Constants';

export function getDefaultImage(imageType) {

    switch (imageType) {
        case ImageConstants.imageType.unavailableImage:
            return getUrl(ImageConstants.imageFilePath.unavailablePicture);
            break;
        case ImageConstants.imageType.happyBirthDayImage:
            return getUrl(ImageConstants.imageFilePath.unavailablePicture);
            break;
        default:
            return getUrl(ImageConstants.imageFilePath.defaultImage);
            break;
    }
}

async function getUrl(fileName) {
    const url = await Storage.get(fileName, {expires: 60});
    console.log("Url = ", url);
    return url;
}

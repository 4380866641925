import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import Tooltip from "@mui/material/Tooltip";
import Fab from '@mui/material/Fab';
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import { BsCalendarDate, BsPeople } from "react-icons/bs";
import PhoneIcon from "@mui/icons-material/Phone";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonPinIcon from "@mui/icons-material/PersonPin";

import { Widget } from "react-chat-widget";
import "react-chat-widget/lib/styles.css";
import EventDetailsBody from "./EventDetailsBody";
import EventGuests from "./EventGuests";
import EventChat from "./EventChat";
import EventTodoList from "./EventTodoList";
import EventMedia from "./EventMedia";
import { Container } from "@mui/material";
import ChatApp from "../../chat/ChatApp";
import { useNavigate } from "react-router-dom";
import GuestRsvpForm from "../../guests/GuestRsvpForm";
import GuestRsvpPage from "../../guests/GuestRsvpPage";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const handleNewUserMessage = (newMessage) => {
  console.log(`New message incoming! ${newMessage}`);
  // Now send the message throught the backend API
};

export default function EventDetailsTabsPanel(props) {
  const [value, setValue] = React.useState(props.initialTabPanel);
  const navigate = useNavigate();

  console.log("props: ", props);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isCoOwner = false; // TODO: set this boolean using event.coOwner attrinbute
  const isOwner = props.event.ownerID === props.member.memberID;

  const handleOnClickChat = () => {
    console.log("handleOnClickChat");
    navigate(`/chat/${props.event.eventID}`)
  }

  return (
    <Container disableGutters >
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        //variant="scrollable"
        scrollButtons="auto"
        aria-label="Walkthru the Event"
        centered={true}
      >
        <Tab
          icon={
            <Tooltip title="Event Details">
              <DescriptionOutlinedIcon />
            </Tooltip>
          }
          aria-label="Event Details"
        />
        <Tab
          icon={
            <Tooltip title="Event Guest List">
              <PeopleAltOutlinedIcon />
            </Tooltip>
          }
          aria-label="Guest List"
        />
      <Fab color="primary" position='TopRight' aria-label="add" onClick={handleOnClickChat}>
        <ChatOutlinedIcon />
      </Fab>
        <Tab
          icon={
            <Tooltip title="Event Photo & Video">
              <CameraAltOutlinedIcon />
            </Tooltip>
          }
          aria-label="Photo / Video"
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <EventDetailsBody {...props} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* {isOwner &&  */}
        <EventGuests {...props} />
      {/* } */}
{/* 
        {!isOwner && 
        <GuestRsvpPage {...props} />}
 */}
      </TabPanel>
      <TabPanel value={value} index={4}>
        <EventMedia {...props} />
      </TabPanel>

    </Box>
    </Container>
  );
}

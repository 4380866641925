import React from "react";
import { Grid } from "@material-ui/core";

const LoadingPage = (props) => {
  return (
  <Grid>
    <br/>
    <br/>
    {props.message}
  </Grid>);
};

export default LoadingPage;

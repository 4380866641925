import React from "react";
import axios from "axios";
import EventDetailsPage from "./EventDetailsPage";
import Header from "../../../UI/Header";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import NoDataFound from "../../../UI/NoDataFound";
import LoadingPage from "../../../UI/LoadingPage";
import ErrorPage from "../../../UI/ErrorPage";
import Constants from "../../../Services/Constants";
import { Box, Button } from "@mui/material";

const EventDetails = (props) => {
  const url = Constants.APIUrlString.PrivEvents + "/event?eventID=";
  var { eventID, userID, userName, openRsvp } = useParams();
  const { data: currentEvent, error, isPending } = useFetch(url + eventID);
  const member = props.member;

  console.log("Event fetched = ", currentEvent)

  var initialTabPanel = 0;
  if (openRsvp) {
    if (openRsvp > 0) {
      initialTabPanel = 1; // Guest list tab has to be opened
    }
  }

  if (typeof userID === "undefined") {
    userID = currentEvent.ownerID;
  }
  if (currentEvent) {
    userName = currentEvent.eventOwner;
  }
  
  if (typeof openRsvp === "undefined") {
    openRsvp = 0;
  }

  // console.log(" event ID    (2) = ", eventID)
  // console.log(" user ID     (2) = ", userID)
  // console.log(" event owner (2) = ", userName)
  // console.log(" openRSVP    (2) = ", openRsvp)

  return (
    <Box>
      <Header 
        userID={member.memberID} 
        userName={member.memberName} 
        userPictureUrl={member.memberPictureUrl} 
        {...props}
        title="Event Details Page"
        />
        <br></br>

      <Box>
        <Button></Button>
      </Box>

      {error && <ErrorPage message={error} />}
      {isPending && <LoadingPage message="Loading Data..." />}

      {currentEvent && (
        <EventDetailsPage
          userID={userID}
          userName={userName}
          initialTabPanel={initialTabPanel}
          eventOwner={userName}
          openRsvp={openRsvp}
          event={currentEvent}
          {...currentEvent}
          {...props}
        />
      )}

    </Box>
  );
};

export default EventDetails;

import React from "react";
import { Grid } from "@material-ui/core";
import "../Events.css";
import axios from "axios";
import PublicEventCard from "./PublicEventCard";
import NoDataFound from "../../../UI/NoDataFound";
import Box from "@mui/material/Box";

import BottomBar from "../../../UI/BottomBar";

export default class PublicEventsList extends React.Component {
  state = {
    events: [],
    isEmpty: false,
  };

  componentDidMount() {
    var metroArea = this.props.metroArea;
    var RESTUrl =
      "https://1qiaqxyiec.execute-api.us-west-2.amazonaws.com/pub/events?queryKey=eventMetroArea&queryValue=" +
      metroArea;

    console.log("REST API Url: ", RESTUrl);

    axios.get(RESTUrl).then((res) => {
      const events = res.data;
      const isEmpty = events.length === 0;
      this.setState({ events, isEmpty });
    });
  }

  render() {
    return (
      <Box>
        {this.state.isEmpty ? (
          <NoDataFound message="There are no public event available in your area at the moment" />
        ) : (
          <ul>
            {this.state.events.map((evt) => (
              <li key={evt.eventID}>
                <PublicEventCard key={evt.eventID} {...evt} />
              </li>
            ))}
          </ul>
        )}

        <BottomBar showCreate="0" showFilter="1" showSort="1" />
      </Box>
    );
  }
}

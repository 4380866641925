import React, { useEffect, useRef, useState } from "react";
import Avatar from "@mui/material/Avatar";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { IconButton } from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import InputEmoji from "react-input-emoji";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardFooter,
  MDBIcon,
  MDBBtn,
  MDBScrollbar,
} from "mdb-react-ui-kit";
import "./ChatEventConversation.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";

import ChatHeader from "./ChatHeader";
import { Tooltip } from "@material-ui/core";
import { useNavigate } from "react-router";

function ChatEventConversation(props) {
  console.log("Props: ", props);
  const [message, setMessage] = useState("");
  const messagesEndRef = useRef(null);

  const messages = props.messages;
  const member = props.member;

  const currentLocale =
    props.hasOwnProperty("currentLocale") && props.currentLocale !== ""
      ? props.currentLocale
      : new Intl.NumberFormat().resolvedOptions().locale;
  const currentTimeZone =
    props.hasOwnProperty("currentTimeZone") && props.currentTimeZone !== ""
      ? props.currentTimeZone
      : new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const optionsLocalTime = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: currentTimeZone,
  };
  const eventLocalDateTime =
    new Date(Math.floor(props.event.eventStartDateTimeUTC)).toLocaleDateString(
      currentLocale,
      optionsLocalTime
    ) + " (my time)";

  const handleRefresh = () => {
    console.log("Refreshing the messages from eventID: ", props.event.eventID);
    props.setTargetEventID(props.event.eventID);
    props.loadMessages(props.event.eventID);
    console.log("Messages: ", messages);
    scrollToBottom();
  };

  const scrollToBottom = () => {
    console.log("Scrolling to the bottom of the page");
    messagesEndRef.current.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  });

  const onClickSend = () => {
    props.setTargetEventID(props.targetEventID);
    props.sendMessage(message);
    props.setTypingConnections(new Map());
    props.setSomeOneIsTyping(false);
    setMessage("");
    scrollToBottom();
  };
  const navigate = useNavigate();
  function handleBackButton(e) {
    console.log("navigateBackToList: ", props.navigateBackToList);
    if (props.navigateBackToList) {
      props.setTargetEvent({eventID:"", eventName:""});
      props.setTargetEventID("");
      navigate(`/chat`);
    } else {
      navigate(-1);
    }
  }

  const handleOnChange = (e) => {
    if (e !== null && e !== "" && e !== undefined) {
      console.log("handleOnChange() - eventID: ", props.targetEventID);
      props.setTargetEventID(props.targetEventID);
      setMessage(e);
      props.sendTyping(props.targetEventID);
    }
  };

  return (
    <div>
      {/* <ChatHeader title={props.event.eventName} refresh={handleRefresh} /> */}

      <MDBContainer fluid className="py-5 chat-conversation-size" 
        style={{ backgroundColor: "#eee" }}>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol md="10" lg="8" xl="6">
            <MDBCard id="chat2" style={{ borderRadius: "15px" }}>
              <MDBCardHeader className="d-flex justify-content-between align-items-center p-3 bg-gainsboro">
                <MDBBtn size="sm">
                  <IconButton onClick={handleBackButton}>
                    <ArrowBackIosOutlinedIcon />
                  </IconButton>
                </MDBBtn>
                <h5 className="mb-0">{props.event.eventName}</h5>
                <MDBBtn size="sm">
                  <IconButton onClick={handleRefresh}>
                    <RefreshOutlinedIcon />
                  </IconButton>
                </MDBBtn>
              </MDBCardHeader>
              <div class="my-custom-scrollbar my-custom-scrollbar-primary">
                <MDBCardBody>
                  {messages.length < 1 && (
                    <div>
                      <p className="d-flex justify-content-center align-items-center p-3 bg-primary">
                        Welcome to the Chat!
                      </p>
                    </div>
                  )}
                  {messages.map((rec, key) => (
                    <div key={key}>
                      {rec.sender.memberID !== member.memberID && (
                        <div className="d-flex flex-row justify-content-start">
                          <Avatar
                            src={rec.sender.memberPictureUrl}
                            alt="avatar 1"
                            style={{ width: "30px", height: "30px" }}
                          />
                          <div>
                            <p className="xsmall me-3 ms-3 mb-3 rounded-3 text-muted d-flex justify-content-start">
                              {rec.sender.memberName}
                            </p>
                            <p className="small justify-content-start p-2 ms-3 mb-1 text-white rounded-3 bg-primary">
                              {rec.message}
                            </p>
                            <p className="xsmall me-3 ms-3 mb-3 rounded-3 text-muted d-flex justify-content-start">
                              {new Date(
                                Math.floor(rec.createDateTimeUTC)
                              ).toLocaleDateString(
                                currentLocale,
                                optionsLocalTime
                              ) + " (my time)"}
                            </p>
                          </div>
                        </div>
                      )}

                      {rec.sender.memberID === member.memberID && (
                        <div className="d-flex flex-row justify-content-end mb-4 pt-1">
                          <div>
                            <p
                              className="small p-2 me-3 mb-1 text-black rounded-3 justify-content-end"
                              style={{ backgroundColor: "#D3D3D3" }}
                            >
                              {rec.message}
                            </p>
                            <p className="xsmall me-3 ms-3 mb-3 rounded-3 text-muted d-flex justify-content-end">
                              {new Date(
                                Math.floor(rec.createDateTimeUTC)
                              ).toLocaleDateString(
                                currentLocale,
                                optionsLocalTime
                              ) + " (my time)"}
                            </p>
                          </div>
                          <Avatar
                            src={member.memberPictureUrl}
                            alt="avatar 1"
                            style={{ width: "30px", height: "30px" }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </MDBCardBody>
                <div ref={messagesEndRef}></div>
              </div>
              <div>

              { props.someOneIsTyping &&
                props.typingConnections.has(props.event.eventID) &&
                props.typingConnections.get(props.event.eventID).memberName !==
                  member.memberName && (
                  <span>
                    {props.typingConnections.get(props.event.eventID)
                      .memberName + " is typing...."}
                  </span>
                )}

              { props.someOneIsTyping !== true &&
                (<span> </span>)}

              </div>

              <MDBCardFooter className="text-muted d-flex justify-content-start align-items-center p-3">
                <Avatar
                  src={member.memberPictureUrl}
                  alt="avatar 3"
                  style={{ width: "30px", height: "30px" }}
                />
                <InputEmoji
                  value={message}
                  onChange={(e) => handleOnChange(e)}
                  cleanOnEnter
                  onEnter={(e) => {
                    console.log("onEnter: ", e);
                    onClickSend(e);
                  }}
                  placeholder="Type a message"
                />

                <Tooltip title="Send your message">
                  <IconButton
                    aria-label="Chat"
                    onClick={(e) => {
                      console.log("onClick e: ", e);
                      onClickSend(e.target.value);
                    }}
                  >
                    <SendOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </MDBCardFooter>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default ChatEventConversation;

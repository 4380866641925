
function getDefaultMemberPicture(memberID) {
    const DefaultMembersPictureUrl = [
      {
        memberID: "399f7048-db59-4f2e-adf6-d08115dd0372",
        memberPictureUrl:
          "https://i.discogs.com/4uzH8yJPurWALi83f-3y33VdSr0ISCTXulNj7Jbr9vs/rs:fit/g:sm/q:90/h:573/w:568/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTE1NTAx/NTUtMTIyOTMxOTk3/Mi5qcGVn.jpeg",
        memberName: "Charlene Gaydu"
      },
      {
        memberID: "c9179ae9-e662-4924-a629-b35173c5c265",
        memberPictureUrl:
          "https://media-exp1.licdn.com/dms/image/C5603AQHdm8REgKetbw/profile-displayphoto-shrink_200_200/0/1606452950900?e=2147483647&v=beta&t=h4_-zV60Ic4T2Av4leTFqPj2EWnw69nboLn0ADplxlI",
        memberName: "Charles Dika"
      },
      {
        memberID: "465028e7-1e52-470e-8969-b2095792c114",
        memberPictureUrl:
          "https://media-exp1.licdn.com/dms/image/C4D03AQEKkKoWXT_QQA/profile-displayphoto-shrink_200_200/0/1516850442433?e=1652918400&v=beta&t=9x59GXPnH4FTWZeLybKBvIU3oiOavdMrVfp_Q6SLxMs",
        memberName: "Rosine Dika"
      },
      {
        memberID: "5d869d08-ea02-4d9c-9f1b-abd623998305",
        memberPictureUrl:
          "https://media-exp1.licdn.com/dms/image/C4E03AQHr4ldOTVuZNQ/profile-displayphoto-shrink_200_200/0/1516960147950?e=1652918400&v=beta&t=e-s2s0zez09cLvYkM6rFWKnxAJDNb1pjl-q68OrRPdE",
        memberName: "Loic Dika"
      },
      {
        memberID: "a8a7e96e-b969-4d30-8c42-cf4f58306d98",
          memberPictureUrl:
          "https://media-exp1.licdn.com/dms/image/C4E03AQFDZl_CW1jR0Q/profile-displayphoto-shrink_200_200/0/1644347347264?e=2147483647&v=beta&t=dH6MVYgvqjWwsDJO6L6JLsbMSLdBBVvWxITNxIddMm4",
        memberName: "Yann Dika"
      },
      {
        memberID: "89734160-eb17-4ad5-8297-3c5ec2b705a4",
        memberPictureUrl:
          "https://gothunderbirds.ca/images/2021/9/21/Dika_Balotoken_Eric_DSC00577.jpg?width=300",
        memberName: "Eric Dika"
      },
      {
        memberID: "1b13b98e-22ff-48bf-b7b4-8a6333ac6011",
        memberPictureUrl:
          "https://media-exp1.licdn.com/dms/image/C4D03AQGt5gBPR8PVLA/profile-displayphoto-shrink_200_200/0/1578692120882?e=1655337600&v=beta&t=5P1CP2IegGCYQLYjj9HX8bS4A35xIrCAGvV5lCj49Bw",
        memberName: "Agatha Bartoszewicz"
      },
    ];

    let i = 0;
    while ((i < DefaultMembersPictureUrl.length) 
        && (DefaultMembersPictureUrl[i].memberID !== memberID)) {
      i++;
    }
    if ( i >= DefaultMembersPictureUrl.length ) {
      return {
        memberID: "",
        memberPictureUrl:
          "https://media.npr.org/assets/img/2017/09/12/macaca_nigra_self-portrait-3e0070aa19a7fe36e802253048411a38f14a79f8-s1100-c50.jpg",
        memberName: "Unknown Member"
      }
    }
    return DefaultMembersPictureUrl[i];
    //    return DefaultMembersPictureUrl.find((e) => (e.memberID = memberID));
  }

  export default getDefaultMemberPicture;
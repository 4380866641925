import React, { useEffect, useRef, useState } from "react";
import "./ChatApp.css";
import { WebSocketConnector } from "./WebSocketConnector.ts";
import ChatEventsList from "./ChatEventsList";
import Header from "../../UI/Header";
import { useParams } from "react-router";
import ChatEventConversation from "./ChatEventConversation";
import useFetch from "../../hooks/useFetch";

//const WS_URL = "wss://" + process.env.CHAT_WEBSOCKET_ENDPOINT;
const WEBSOCKET_HOST = "794q337u0g.execute-api.us-west-2.amazonaws.com/dev/";
const WEBSOCKET_URL = "wss://" + WEBSOCKET_HOST;
const WEBSOCKET_CALLBACK_URL = "https://" + WEBSOCKET_HOST + "@connections";
const EVENT_RESTAPI_HOST = "j5f6ocpa5b.execute-api.us-west-2.amazonaws.com";
const PRIVATE_EVENT_RESTAPI_URL = "https://" + EVENT_RESTAPI_HOST + "/priv/event";

const connector = new WebSocketConnector();
const WEBSOCKET_RETRY_TIMEOUT = 10000;

function ChatApp(props) {
  const currentMember = props.member;
  const userID = props.userID;

  console.log("Props: ", props);
  console.log("Current Member: ", currentMember);
  console.log("userID: ", userID);

  // List of current connections
  const [connections, setConnections] = useState([]);
  // List of events
  const [myEvents, setMyEvents] = useState([]);
  const [targetEventID, setTargetEventID] = useState("");
  const [messages, setMessages] = useState([]);
  let { eventID } = useParams();
  if (eventID === undefined || eventID === "") {
    eventID = props.eventID;
  }

  const [currentConnection, setCurrentConnection] = useState({});
  const [typingConnections, setTypingConnections] = useState(new Map());
  const [someOneIsTyping, setSomeOneIsTyping] = useState(false);

  const eventUrl = PRIVATE_EVENT_RESTAPI_URL + "?eventID=" + eventID;
  const { data: targetEvent, error, isLoading, isEmpty } = useFetch(eventUrl);

  const webSocket = useRef(connector);
  const url = WEBSOCKET_URL + "?memberID=" + props.userID;
  const ws = webSocket.current.getConnection(url);

  //
  const loadMyEvents = () => {
    console.log("loading my events - url: ", url);
    try {
      webSocket.current
      .getConnection(url)
      .send(JSON.stringify({ action: "getMyEvents" }));
    } catch(e) {
      console.log("loadMyEvents ERROR: ", e);
      const eString = e.toString();
      console.log("eString: ", eString);
      if (eString.includes("CONNECTING")) {
        const loadTimeout = setTimeout(
          () => {
            console.log("Wait 5 sec. before re-trying loadMyEvents");
            try {
              webSocket.current
              .getConnection(url)
              .send(JSON.stringify({ action: "getMyEvents" }));        
            } catch(e) {
              console.error("Re-trying getMyEvents ERROR: ", e);
            }
          }
        , WEBSOCKET_RETRY_TIMEOUT)
      }
    }
  };

  // Load messages
  const loadMessages = (ID) => {
    console.log("Loading messsage for eventID: ", ID);
    try {
      console.log(" Sending getMessages for eventID: ", ID);
      webSocket.current.getConnection(url).send(
        JSON.stringify({
          action: "getMessages",
          eventID: ID,
          limit: 1000,
        })
      );
    } catch (e) {
      console.log("loadMessages <" + ID + "> ERROR: ", e);
      const eString = e.toString();
      console.log("eString: ", eString);
      if (eString.includes("CONNECTING")) {
        const loadTimeout = setTimeout(
          () => {
            console.log("Wait 5 sec. before re-trying getMessages - eventID: ", ID);
            try {
              webSocket.current.getConnection(url).send(
                JSON.stringify({
                  action: "getMessages",
                  eventID: ID,
                  limit: 1000,
                })
              );
            } catch(e) {
              console.error("Re-trying getMessage ERROR: ", e);
            }
          }
        , WEBSOCKET_RETRY_TIMEOUT)
      }
    }
  };

  // Load messages
  const loadEventMessages = (ID) => {
    console.log("Loading (event) messsage for ID: ", ID);
    try {
      webSocket.current.getConnection(url).send(
        JSON.stringify({
          action: "getMessages",
          eventID: ID,
          limit: 1000,
        })
      );
    } catch (e) {
      console.log("loadEventMessages <" + ID + "> ERROR: ", e);
      const eString = e.toString();
      console.log("eString: ", eString);
      if (eString.includes("CONNECTING")) {
        const loadTimeout = setTimeout(
          () => {
            console.log("Wait 5 sec. before re-trying getMessages - eventID: ", ID);
            try {
              webSocket.current.getConnection(url).send(
                JSON.stringify({
                  action: "getMessages",
                  eventID: ID,
                  limit: 1000,
                })
              );
            } catch(e) {
              console.error("Re-trying getMessage ERROR: ", e);
            }
          }
        , WEBSOCKET_RETRY_TIMEOUT)
      }
    }
  };

  // Who send a whoAmI request
  const getWhoAmI = () => {
    console.log("getWhoAmI()");
    try {
      webSocket.current.getConnection(url).send(
        JSON.stringify({
          action: "whoAmI",
        })
      );
    } catch (e) {
      console.log("getWhoAmI() ERROR: ", e);
      const eString = e.toString();
      console.log("eString: ", eString);
      if (eString.includes("CONNECTING")) {
        const loadTimeout = setTimeout(
          () => {
            console.log("Wait 5 sec. before re-trying getWhoAmI");
            try {
              webSocket.current.getConnection(url).send(
                JSON.stringify({
                  action: "whoAmI",
                })
              );        
            } catch(e) {
              console.error("Re-trying getWhoAmI ERROR: ", e);
            }
          }
        , WEBSOCKET_RETRY_TIMEOUT)
      }

    }
  };

  useEffect(() => {
    // get currenct connection info
    try {
      console.log("Trying to get who am I");
      getWhoAmI();
    } catch (e) {
      const eString = e.toString();
      if (eString.includes("CONNECTING state")) {
        try {
          console.log("wait 5 sec. before re-trying to get who am I");
          const loadTimeout = setTimeout(() => {
            getWhoAmI();
          }, WEBSOCKET_RETRY_TIMEOUT);
        } catch (e) {
          console.log("ERROR while get who am I: ", e);
        }
      }
    }

    if (eventID !== undefined && eventID !== null && eventID !== "") {
      console.log("Received this eventID as a param: ", eventID);
      setTargetEventID(eventID);
      try {
        console.log("Trying to load messages for event: ", eventID);
        loadEventMessages(eventID);
      } catch (e) {
        console.error(
          "ERROR while loading <" + eventID + "> messages: ",
          e
        );
      }
    } else {
      console.log("No eventID received as a param - loading all my events ");
      setTargetEventID("");
      try {
        loadMyEvents();
      } catch (e) {
        console.error("ERROR while loading my events: ", e);
      }
    }
  }, [eventID, userID]);

  // Processing incoming messages from the webSocket
  ws.onmessage = (event) => {
    /*
     ** Messages received are
     **  {type: "error" | "events" | "messages"| "getConnections" | "whoAmI"}
     */
    const msg = JSON.parse(event.data);
    console.log(msg);

    // List of all current ES connections
    if (msg.type === "connections") {
      setConnections(msg.value);
      setSomeOneIsTyping(false);
      setTypingConnections(new Map());
    }

    // List of events the current member has access
    if (msg.type === "events") {
      setMyEvents(msg.value);
      setSomeOneIsTyping(false);
      setTypingConnections(new Map());
    }

    // Messages
    if (msg.type === "messages") {
      setMessages(msg.value.messages.reverse());
      setSomeOneIsTyping(false);
      setTypingConnections(new Map());
    }

    if (msg.type === "message") {
      const createDate = Date.now();
      let item = msg.value;
      item.createDateTimeUTC = createDate;
      console.log("Item: ", item);
      setMessages([...messages, item]);
      setSomeOneIsTyping(false);
      setTypingConnections(new Map());
    }

    if (msg.type === "whoami") {
      console.log("Who am I: ", msg.value);
      setCurrentConnection(msg.value);
      setSomeOneIsTyping(false);
      setTypingConnections(new Map());
    }

    if (msg.type === "typing") {
      console.log("Typing: ", msg.value);
      setSomeOneIsTyping(true);
      let newTypingConnections = typingConnections;
      newTypingConnections.set(msg.value.eventID, msg.value.sender);
      setTypingConnections(newTypingConnections);
      console.log("List of typing connections: ", typingConnections);
      //
      const typingTimeout = setTimeout(
        () => {
          setSomeOneIsTyping(false);
          setTypingConnections(new Map());
        }
        , WEBSOCKET_RETRY_TIMEOUT)
    }
  };

  ws.onopen = () => {
    try {
      const wsConnection = webSocket.current.getConnection(url);
      console.log("wsConnection: ", wsConnection);
      setSomeOneIsTyping(false);
      setTypingConnections(new Map());
      // Get the list of all current ws connections
      try {
        wsConnection.send(JSON.stringify({ action: "getConnections" }));
        console.log("onopen().getConnection() done");
      } catch (e) {
        console.log("onopen().getConnection() Exception: ", e);
      }

      // get currenct connection info
      try {
        getWhoAmI();
      } catch (e) {
        console.log("onopen().getWhoAmI() Exception: ", e);
      }

      // Load my events
      try {
        loadMyEvents();
        console.log("onopen().loadMyEvents() done");
      } catch (e) {
        console.log("onopen().loadMyEvents() Exception: ", e);
      }
    } catch (e) {
      console.log("onopen() Exception: ", e);
    }
  };

  ws.onclose = () => {
    console.log("WS onclose() ");
  };

  const callWensocektSend = (wSock, value) => {
    try {
      wSock.send(
        JSON.stringify({
          action: "sendMessage",
          eventID: targetEventID,
          message: value,
        })
      );
    } catch (e) {
      console.error("Sending message ERROR: ", e);
      const eString = e.toString();
    }
  };

  const sendMessage = (value) => {
    console.log("Sending message : ", value);
    console.log("to eventID: ", targetEventID);
    //
    let wSock = null;
    try {
      wSock = webSocket.current.getConnection(url);
    } catch (e) {
      console.log("Error getting websocket: ", e);
      setTimeout(() => {
        console.log("re-trying to get the socekt after 2.5 seconds");
        wSock = webSocket.current.getConnection(url);
      }, WEBSOCKET_RETRY_TIMEOUT);
    }
    if (wSock !== null) {
      try {
        wSock.send(
          JSON.stringify({
            action: "sendMessage",
            eventID: targetEventID,
            message: value,
          })
        );  
        //
        setMessages([
          ...messages,
          {
            message: value,
            sender: currentConnection,
            createDateTimeUTC: Date.now(),
          },
        ]);
      } catch (e) {
        const eString = e.toString();
        if (eString.includes("Still in CONNECTING state")) {
          try {
            const myTimeout = setTimeout(
              () => {
                console.log("wait 5 sec then re-try sendMessage");
                try {
                  wSock.send(
                    JSON.stringify({
                      action: "getMessages",
                      eventID: targetEventID,
                      limit: 1000,
                    })
                  );          
                  wSock.send(
                    JSON.stringify({
                      action: "sendMessage",
                      eventID: targetEventID,
                      message: value,
                    })
                  );          
                } catch(e) {
                  console.error("Unable to send message - ERROR: ", e);
                }
              }
              , WEBSOCKET_RETRY_TIMEOUT
            );
            setMessages([
              ...messages,
              {
                message: value,
                sender: currentConnection,
                createDateTimeUTC: Date.now(),
              },
            ]);
          } catch (e) {
            console.error("Unable to send message - ERROR: ", e);
          }
        }
      }
    }
  };

  const sendTyping = (ID) => {
    console.log("Sending typing ");
    console.log("working on eventID: ", ID);
    //
    try {
      webSocket.current.getConnection(url).send(
        JSON.stringify({
          action: "typing",
          eventID: ID,
        })
      );
    } catch(e) {
      const eString = e.toString();
      if (eString.includes("Still in CONNECTING state")) {
        try {
          const typingTimeout = setTimeout(
            () => {
              try {
                webSocket.current.getConnection(url).send(
                  JSON.stringify({
                    action: "getMessages",
                    eventID: ID,
                    limit: 1000,
                  })
                );          
                //
                webSocket.current.getConnection(url).send(
                  JSON.stringify({
                    action: "typing",
                    eventID: ID,
                  })
                );          
              } catch(e) {
                console.error("sendTyping() ERROR: ", e);
              }
            }
            , WEBSOCKET_RETRY_TIMEOUT)
        } catch(e) {
          console.error("sendTyping - setTimeout() ERROR: ", e);
        }
      }
    }
  };

  return (
    <div className="flex">

      {(eventID === undefined || eventID === null || eventID === "") && (
        <ChatEventsList
          member={currentMember}
          connections={connections}
          events={myEvents}
          targetEventID={eventID}
          messages={messages}
          url={url}
          loadMessages={loadMessages}
          loadMyEvents={loadMyEvents}
          setTargetEventID={setTargetEventID}
          sendMessage={sendMessage}
          sendTyping={sendTyping}
          typingConnections={typingConnections}
          setTypingConnections={setTypingConnections}
          someOneIsTyping={someOneIsTyping}
          setSomeOneIsTyping={setSomeOneIsTyping}
        />
      )}

      {eventID !== undefined && eventID !== null && eventID !== "" && (
        <ChatEventConversation
          member={currentMember}
          connections={connections}
          event={targetEvent}
          targetEventID={eventID}
          messages={messages}
          url={url}
          loadMessages={loadMessages}
          loadMyEvents={loadMyEvents}
          setTargetEventID={setTargetEventID}
          sendMessage={sendMessage}
          sendTyping={sendTyping}
          typingConnections={typingConnections}
          setTypingConnections={setTypingConnections}
          someOneIsTyping={someOneIsTyping}
          setSomeOneIsTyping={setSomeOneIsTyping}
          navigateBackToList={false}
        />
      )}
    </div>
  );
}

export default ChatApp;
